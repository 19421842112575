import { memo, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useGlobalVars } from '../../../App';
import {
  changeAllStatus,
  deleteAllGraders,
  downloadAllGraders,
} from '../../api/grade_api';
import { DocsHelp } from '../docs/Docs';
import Confirm from '../reusable/Confirm';

function DashboardOptions({ courseId, setViewChange, currSize }) {
  const [loading, setLoading] = useState(false);
  const { setShowModal } = useGlobalVars();

  function onActivate() {
    if (currSize === 0) return;
    changeAllStatus(courseId, true).then(() => setViewChange((prev) => !prev));
  }

  function onDeactivate() {
    if (currSize === 0) return;
    changeAllStatus(courseId, false).then(() => setViewChange((prev) => !prev));
  }

  function onDownload() {
    if (currSize === 0) return;
    setLoading(true);
    downloadAllGraders(courseId).then(() => setLoading(false));
  }

  function onDelete() {
    if (currSize === 0) return;
    setShowModal(
      <Confirm
        question='Are you sure you want to delete all graders?'
        confirmVariant='danger'
        onConfirm={() => {
          deleteAllGraders(courseId)
            .then(() => {
              toast.success('Successfully deleted all graders!');
              setViewChange((prev) => !prev);
              setShowModal(null);
            })
            .catch(() => toast.error('Failed to delete all graders!'));
        }}
        onCancel={() => setShowModal(null)}
      />,
    );
  }

  return (
    <div className='d-flex pb-1 align-items-center'>
      <div className='flex-grow-1 fw-semibold bg-light h-100 rounded border ps-2 d-flex align-items-center'>
        Grade Dashboard
        <DocsHelp at={'grade.dashboard'} />
      </div>
      <div className='px-1'>
        <Button
          size='sm'
          variant='light'
          className='border-dark ms-1'
          onClick={onActivate}
        >
          <i className='bi bi-toggle-on' />
          <span className='ms-1'>Activate All</span>
        </Button>
        <Button
          size='sm'
          variant='light'
          className='border-dark ms-1'
          onClick={onDeactivate}
        >
          <i className='bi bi-toggle-off' />
          <span className='ms-1'>Deactivate All</span>
        </Button>
        <Button
          size='sm'
          variant='info'
          className='text-light ms-1'
          onClick={onDownload}
        >
          {loading ? (
            <Spinner animation='border' size='sm' />
          ) : (
            <>
              <i className='bi bi-download' />
              <span className='ms-1'>Download All</span>
            </>
          )}
        </Button>
        <Button
          size='sm'
          variant='danger'
          className='text-light ms-1'
          onClick={onDelete}
        >
          <i className='bi bi-trash-fill' />
          <span className='ms-1'>Delete All</span>
        </Button>
      </div>
    </div>
  );
}

export default memo(DashboardOptions);
