import FileDownload from 'js-file-download';

export function downloadFile(response) {
  const contentDisposition = response.headers['content-disposition'];
  let fileName = 'file';
  if (contentDisposition) {
    const match = contentDisposition.match(/filename=(?:"([^"]+)"|(\S+))/);
    if (match && match[1]) {
      fileName = match[1];
    } else if (match && match[2]) {
      fileName = match[2];
    }
  }
  FileDownload(response.data, fileName);
}

export function downloadHTML(body, name) {
  const htmlContent = `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet">
    <title>${name}</title>
  </head>
  <body>
    ${body}
  </body>
</html>`;
  const htmlBlob = new Blob([htmlContent], { type: 'text/html' });
  FileDownload(htmlBlob, name + '.html');
}

export function downloadCSV(obj, cols, name, headers = null) {
  let csvData = headers ? headers.join(',') + '\n' : '';
  for (const o of obj) {
    const row = cols.map((c) => o[c] || '').join(',');
    csvData += row + '\n';
  }
  const csvBlob = new Blob([csvData], { type: 'text/csv' });
  FileDownload(csvBlob, name + '.csv');
}
