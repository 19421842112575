import { createContext, memo, useContext } from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { DAYS_SHORT } from '../../util/date';
import { DocsHelp } from '../docs/Docs';
import FileSelector from '../reusable/FileSelector';
import SoftwareVersions from './SoftwareVersions';
import TaskComponents from './TaskComponents';

const TaskContext = createContext();
export const useTasks = () => useContext(TaskContext);

function GeneralConfig({
  formData,
  setFormData,
  tasks,
  setTasks,
  editState,
  fileData,
  setFileData,
  validDays,
  handleFileDownload,
  teachers,
}) {
  function setScheduleDays(idx) {
    const updatedDays = formData.schedule.days.map((x, i) =>
      i === idx ? !x : x,
    );
    setFormData({
      ...formData,
      schedule: { ...formData.schedule, days: updatedDays },
    });
  }

  function setDelayHours(hours) {
    const oldMins = formData.schedule.delay_minutes % 60;
    const newHoursInMins = parseInt(hours) * 60;
    setFormData({
      ...formData,
      schedule: {
        ...formData.schedule,
        delay_minutes: parseInt(newHoursInMins + oldMins),
      },
    });
  }

  function setDelayMins(mins) {
    const oldMins = formData.schedule.delay_minutes % 60;
    const oldHoursInMins = formData.schedule.delay_minutes - oldMins;
    setFormData({
      ...formData,
      schedule: {
        ...formData.schedule,
        delay_minutes: oldHoursInMins + parseInt(mins),
      },
    });
  }

  return (
    <>
      <fieldset disabled={!editState}>
        <Col xs={12} sm={4} xl={2} className='bg-light rounded-2 border'>
          <div className='p-2 d-flex flex-column align-items-start'>
            <Form.Label>Active State</Form.Label>
            <Form.Check
              id='extendActive'
              label={`${formData.active ? 'Active' : 'Inactive'}`}
              type={'switch'}
              checked={formData.active}
              onChange={(e) => {
                setFormData({ ...formData, active: e.target.checked });
              }}
            />
          </div>
        </Col>
      </fieldset>
      <hr className='text-muted' />
      <Form.Label>
        Choose Autograder Type
        <DocsHelp at={'grade.config.type'} />
      </Form.Label>
      <fieldset disabled={!editState}>
        <Form.Check
          id='gradeCheck2'
          type='radio'
          label='Run Continuously'
          name='configType'
          onChange={() =>
            setFormData({
              ...formData,
              schedule: { ...formData.schedule, type: 'delay' },
            })
          }
          checked={formData.schedule.type === 'delay'}
        />
        <Form.Check
          id='gradeCheck3'
          type='radio'
          label='Run at Specific Times'
          name='configType'
          onChange={() =>
            setFormData({
              ...formData,
              schedule: { ...formData.schedule, type: 'schedule' },
            })
          }
          checked={formData.schedule.type === 'schedule'}
        />
        <br />
        {formData.schedule.type === 'delay' && (
          <Row>
            <Form.Label>Delay to Set</Form.Label>
            <Col sm={'auto'} className='mb-2'>
              <InputGroup size='sm' hasValidation>
                <Form.Control
                  type={'number'}
                  value={Math.floor(formData.schedule.delay_minutes / 60)}
                  max={24}
                  min={0}
                  required
                  onChange={(e) => setDelayHours(e.target.value)}
                />
                <InputGroup.Text style={{ width: 52 }}>hours</InputGroup.Text>
                <Form.Control.Feedback type='invalid' tooltip>
                  Allowed Hours: 0-23
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
            <Col sm={'auto'} className='mb-2'>
              <InputGroup size='sm' hasValidation>
                <Form.Control
                  type={'number'}
                  value={formData.schedule.delay_minutes % 60}
                  max={59}
                  min={0}
                  required
                  onChange={(e) => setDelayMins(e.target.value)}
                />
                <InputGroup.Text
                  className='rounded-end-2'
                  style={{ width: 52 }}
                >
                  mins
                </InputGroup.Text>
                <Form.Control.Feedback type='invalid' tooltip>
                  Allowed Mins: 0-59
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Row>
        )}
        {formData.schedule.type === 'schedule' && (
          <Row>
            <Form.Label>Days When Grader Runs</Form.Label>
            <Col sm={'auto'} className='mb-4'>
              <Form.Group className='border rounded-1 p-1'>
                {Array(7)
                  .fill()
                  .map((_, i) => (
                    <Form.Check
                      key={i}
                      inline
                      id={`gradeDays${i}`}
                      label={DAYS_SHORT[i]}
                      checked={formData.schedule.days[i]}
                      onChange={() => setScheduleDays(i)}
                      isInvalid={validDays}
                    />
                  ))}
              </Form.Group>
            </Col>
            <Form.Label>Hour When Grader Runs</Form.Label>
            <Col sm={'auto'}>
              <InputGroup size='sm' hasValidation>
                <Form.Control
                  type={'number'}
                  value={formData.schedule.hour}
                  max={23}
                  min={0}
                  required
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      schedule: {
                        ...formData.schedule,
                        hour: parseInt(e.target.value),
                      },
                    });
                  }}
                />
                <InputGroup.Text>: 00 MT</InputGroup.Text>
                <Form.Control.Feedback type='invalid' tooltip>
                  Hour must be between 0 and 23
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Row>
        )}
      </fieldset>
      <br />
      <Form.Label>
        Network Configuration
        <DocsHelp at={'grade.config.networking'} />
      </Form.Label>
      <fieldset disabled={!editState}>
        <Form.Check
          id='gradeCheck11'
          label={'Enable Networking (SSH/Internet/Sockets)'}
          type={'checkbox'}
          checked={formData.networking}
          onChange={(e) => {
            setFormData({ ...formData, networking: e.target.checked });
          }}
        />
      </fieldset>
      <br />
      <Form.Label>
        Canvas Grade Settings
        <DocsHelp at={'grade.config.canvas_grade'} />
      </Form.Label>
      <fieldset disabled={!editState}>
        <Form.Check
          id='gradeCheck12'
          label={'Keep Latest Grade'}
          type={'radio'}
          name='canvasType'
          checked={!formData.keep_highest}
          onChange={() => {
            setFormData({ ...formData, keep_highest: false });
          }}
        />
        <Form.Check
          id='gradeCheck13'
          label={'Keep Highest Grade'}
          type={'radio'}
          name='canvasType'
          checked={formData.keep_highest}
          onChange={() => {
            setFormData({ ...formData, keep_highest: true });
          }}
        />
      </fieldset>
      <br />
      <Row>
        <Form.Label>
          Canvas Comment Settings
          <DocsHelp at={'grade.config.canvas_comment'} />
        </Form.Label>
        <Col xs={'auto'}>
          <fieldset disabled={!editState}>
            <InputGroup size='sm'>
              <InputGroup.Text>Commenter</InputGroup.Text>
              <Form.Select
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    user_id: parseInt(e.target.value),
                  })
                }
                value={formData.user_id}
              >
                {teachers &&
                  teachers.map((x) => (
                    <option
                      key={x.id}
                      value={x.id}
                    >{`${x.name} (${x.type})`}</option>
                  ))}
              </Form.Select>
            </InputGroup>
          </fieldset>
        </Col>
      </Row>
      <br />
      <fieldset disabled={!editState}>
        <SoftwareVersions formData={formData} setFormData={setFormData} />
      </fieldset>
      {(editState || fileData) && (
        <>
          <br />
          <Form.Label>
            Grading Assets
            <DocsHelp at={'grade.config.grading_assets'} />
          </Form.Label>
          <fieldset disabled={!editState}>
            {fileData === null ? (
              <Col xs={12} md={8} xl={6}>
                <FileSelector fileData={fileData} setFileData={setFileData} />
              </Col>
            ) : (
              <Col xs={12} md={8} xl={6}>
                <ButtonGroup className='w-100 border overflow-hidden'>
                  <Button
                    variant='light'
                    className='w-90 truncateTxt text-center'
                    disabled
                  >
                    {fileData.name}
                  </Button>
                  <Button variant='light' onClick={() => setFileData(null)}>
                    <i
                      className={
                        'bi bi-trash-fill' + (editState ? ' text-danger' : '')
                      }
                    ></i>
                  </Button>
                  <Button variant='light' onClick={handleFileDownload}>
                    <i
                      className={
                        'bi bi-cloud-arrow-down-fill' +
                        (editState ? ' text-info' : '')
                      }
                    ></i>
                  </Button>
                </ButtonGroup>
              </Col>
            )}
          </fieldset>
        </>
      )}
      <br />
      <Form.Label>
        Tasks
        <DocsHelp at={'grade.tasks'} />
      </Form.Label>
      <TaskContext.Provider value={{ tasks, setTasks, editState }}>
        <TaskComponents />
      </TaskContext.Provider>
    </>
  );
}

export default memo(GeneralConfig);
