import { memo, useEffect, useState } from 'react';
import { Alert, Button, Form, InputGroup } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';
import { useGlobalVars } from '../../../../App';
import { getInspection } from '../../../api/inspect_api';
import { getDateFromObjectID } from '../../../util/date';
import { downloadCSV } from '../../../util/file_download';
import { title } from '../../../util/string_methods';
import { DocsHelp } from '../../docs/Docs';
import AdvancedTable from '../../reusable/AdvancedTable';
import LoadingSpinner from '../../reusable/LoadingSpinner';
import { getMatchColor } from '../Dashboard';
import ReportModal from './ReportModal';

function TextReport({ stat, courseId }) {
  const [currStat, setCurrStat] = useState(stat);
  const { setActiveAssignment } = useGlobalVars();

  function getAndSetData(id) {
    getInspection(courseId, stat.assignment_id, 'text', id).then((res) => {
      if (res.data === null) res.data = [];
      res.data.forEach((x, i) => {
        res.results[i].h = x.h;
        res.results[i].t = x.t;
      });
      setCurrStat(res);
    });
  }

  useEffect(() => {
    setActiveAssignment({ id: stat.assignment_id, name: stat.name });
    getAndSetData(currStat?._id || null);
  }, [stat]);

  return currStat ? (
    <Routes>
      <Route
        path=''
        element={
          <Dashboard currStat={currStat} getAndSetData={getAndSetData} />
        }
      />
    </Routes>
  ) : (
    <LoadingSpinner text='Loading Report...' />
  );
}

function Dashboard({ currStat, getAndSetData }) {
  const { setShowModal, activeCourse, activeAssignment } = useGlobalVars();
  const COLUMNS = [
    {
      id: 's',
      value: 'Student Name',
      headerStyle: 'ps-3',
      searchable: true,
      sortable: true,
    },
    {
      id: 'f',
      value: 'File Name',
      searchable: true,
      sortable: true,
    },
    {
      id: 'w',
      value: 'Total Words',
      searchable: false,
      sortable: true,
    },
    {
      id: 'aiw',
      value: 'Likely AI Words',
      searchable: false,
      sortable: true,
    },
    {
      id: 'p',
      value: '% AI Generated',
      searchable: false,
      sortable: true,
    },
  ];

  function makeRow(x) {
    return (
      <>
        <td className='ps-3 fw-semibold'>{title(x.s.replaceAll('_', ' '))}</td>
        <td>{x.f}</td>
        <td>{x.w}</td>
        <td>{x.aiw}</td>
        <td style={getMatchColor(x.p)}>{x.p}</td>
      </>
    );
  }

  function onClickDownload() {
    const fileName =
      'Text_Inspection-' +
      activeCourse.code +
      '-' +
      activeAssignment.name.slice(
        0,
        Math.min(activeAssignment.name.length, 20),
      ) +
      '-' +
      getDateFromObjectID(currStat._id);

    const cols = ['s', 'f', 'w', 'aiw', 'p'];
    const headers = ['student', 'file', 'words', 'ai_words', 'percentage'];
    downloadCSV(currStat.results, cols, fileName.replaceAll(' ', '_'), headers);
  }

  return (
    <div className='d-flex flex-column h-100'>
      <InputGroup size='md' className='mb-1'>
        <InputGroup.Text className='text-primary fw-bold'>
          Choose Inspection to View
          <DocsHelp at={'inspect.text.view_text_inspection'} />
        </InputGroup.Text>
        <Form.Select
          value={currStat._id}
          onChange={(e) => getAndSetData(e.target.value)}
        >
          {currStat.runs &&
            currStat.runs.map((x, i) => (
              <option key={i} value={x}>
                {getDateFromObjectID(x)}
              </option>
            ))}
        </Form.Select>
        {Array.isArray(currStat.results) && (
          <Button
            variant='info'
            className='text-light'
            onClick={onClickDownload}
          >
            Download as CSV
            <i className='ms-1 bi bi-download' />
          </Button>
        )}
      </InputGroup>
      <div className='flex-grow-1 bg-light border overflow-auto rounded-3'>
        {currStat.results &&
          (Array.isArray(currStat.results) ? (
            <AdvancedTable
              columns={COLUMNS}
              data={currStat.results}
              makeRow={makeRow}
              hover
              onClickRow={(x) =>
                setShowModal(
                  <ReportModal
                    row={x}
                    dt={getDateFromObjectID(currStat._id)}
                  />,
                )
              }
              className='small text-center mb-0'
            />
          ) : (
            <Alert variant='danger' className='m-2 text-center'>
              {currStat.results}
            </Alert>
          ))}
      </div>
    </div>
  );
}

export default memo(TextReport);
