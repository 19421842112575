import axios from 'axios';
import { downloadFile } from '../util/file_download';
import { BACKEND_URL } from './api';

const RULES_CACHE_ID = 'nudge-cache-id';

function getCacheId(courseId) {
  return RULES_CACHE_ID + '-' + courseId;
}

export default function getRules(courseID) {
  return axios
    .get(BACKEND_URL + '/courses/' + courseID + '/nudge', {
      id: getCacheId(courseID),
    })
    .then((response) => response.data);
}

export function getNudgeStats(courseID) {
  return axios
    .get(BACKEND_URL + '/courses/' + courseID + '/nudge/stats')
    .then((response) => response.data);
}

export function downloadAllRules(courseID) {
  return axios
    .get(BACKEND_URL + '/courses/' + courseID + '/nudge/download-all', {
      responseType: 'blob',
    })
    .then((response) => {
      downloadFile(response);
      return response;
    });
}

export function changeAllStatus(courseId, status) {
  return axios.put(
    BACKEND_URL + '/courses/' + courseId + '/nudge/active',
    { active: status },
    {
      cache: {
        update: {
          [getCacheId(courseId)]: (rulesCache, response) => {
            if (rulesCache.state !== 'cached' || response.status !== 200) {
              return 'ignore';
            }

            for (const rule of rulesCache.data.data) {
              rule.active = status;
            }
            return rulesCache;
          },
        },
      },
    },
  );
}

export function addRule(courseID, newRule, asgCount, stuCount) {
  const modRule = {
    ...newRule,
    students_to_include:
      newRule.students_to_include.length === stuCount
        ? []
        : newRule.students_to_include,
    assignments_to_include:
      newRule.assignments_to_include.length === asgCount
        ? []
        : newRule.assignments_to_include,
  };
  delete modRule.emails_sent;
  return axios.post(BACKEND_URL + '/courses/' + courseID + '/nudge', modRule, {
    cache: {
      update: {
        [getCacheId(courseID)]: (rulesCache, response) => {
          if (rulesCache.state !== 'cached' || response.status !== 200) {
            return 'ignore';
          }
          rulesCache.data.data.push(newRule);
          return rulesCache;
        },
      },
    },
  });
}

export function updateRule(courseID, ruleID, editedRule, asgCount, stuCount) {
  const modRule = {
    ...editedRule,
    students_to_include:
      editedRule.students_to_include.length === stuCount
        ? []
        : editedRule.students_to_include,
    assignments_to_include:
      editedRule.assignments_to_include.length === asgCount
        ? []
        : editedRule.assignments_to_include,
  };
  delete modRule.emails_sent;
  return axios.put(
    BACKEND_URL + '/courses/' + courseID + '/nudge/' + ruleID,
    modRule,
    {
      cache: {
        update: {
          [getCacheId(courseID)]: (rulesCache, response) => {
            if (rulesCache.state !== 'cached' || response.status !== 200) {
              return 'ignore';
            }

            let c = 0;
            for (const rule of rulesCache.data.data) {
              if (rule?.name === ruleID) break;
              c += 1;
            }
            rulesCache.data.data[c] = editedRule;
            return rulesCache;
          },
        },
      },
    },
  );
}

export function deleteRule(courseID, ruleID) {
  return axios.delete(
    BACKEND_URL + '/courses/' + courseID + '/nudge/' + ruleID,
    {
      cache: {
        update: {
          [getCacheId(courseID)]: (rulesCache, response) => {
            if (rulesCache.state !== 'cached' || response.status !== 200) {
              return 'ignore';
            }

            const data = rulesCache.data.data;
            for (let i = data.length - 1; i >= 0; i--) {
              if (data[i]?.name === ruleID) data.splice(i, 1);
            }
            return rulesCache;
          },
        },
      },
    },
  );
}

export function deleteAllRules(courseID) {
  return axios.delete(BACKEND_URL + '/courses/' + courseID + '/nudge', {
    cache: {
      update: {
        [getCacheId(courseID)]: (rulesCache, response) => {
          if (rulesCache.state !== 'cached' || response.status !== 200) {
            return 'ignore';
          }

          rulesCache.data.data = [];
          return rulesCache;
        },
      },
    },
  });
}
