import { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import Error from '../reusable/Error';
import Extend from './Extend';
import Statistics from './Statistics';

function ExtendRouting({ courseId, students, assignments }) {
  return (
    <Routes>
      <Route
        path=''
        element={
          <Extend
            courseId={courseId}
            students={students}
            assignments={assignments}
          />
        }
      />
      <Route
        path='/statistics'
        element={<Statistics courseId={courseId} assignments={assignments} />}
      />
      <Route
        path='/*'
        element={<Error error={404} message={'Page was not found!'} />}
      />
    </Routes>
  );
}

export default memo(ExtendRouting);
