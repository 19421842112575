import { memo, useEffect, useState } from 'react';
import { Alert, Button, Form, InputGroup } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';
import { useGlobalVars } from '../../../../App';
import { getInspection } from '../../../api/inspect_api';
import { getDateFromObjectID } from '../../../util/date';
import { downloadCSV } from '../../../util/file_download';
import { title } from '../../../util/string_methods';
import { DocsHelp } from '../../docs/Docs';
import AdvancedTable from '../../reusable/AdvancedTable';
import LoadingSpinner from '../../reusable/LoadingSpinner';
import { getMatchColor } from '../Dashboard';
import ReportModal from './ReportModal';

function CodeReport({ stat, courseId }) {
  const [currStat, setCurrStat] = useState(stat);
  const { setActiveAssignment } = useGlobalVars();

  function getAndSetData(id) {
    getInspection(courseId, stat.assignment_id, 'code', id).then((res) => {
      let updatedRes = res;
      if (Array.isArray(res.results)) {
        updatedRes = {
          ...res,
          results: res.results.map((r, i) => {
            return { ...r, ...res.data[i] };
          }),
        };
        delete updatedRes.data;
      }
      setCurrStat(updatedRes);
    });
  }

  useEffect(() => {
    setActiveAssignment({ id: stat.assignment_id, name: stat.name });
    getAndSetData(currStat?._id || null);
  }, [stat]);

  return currStat ? (
    <Routes>
      <Route
        path=''
        element={
          <Dashboard currStat={currStat} getAndSetData={getAndSetData} />
        }
      />
    </Routes>
  ) : (
    <LoadingSpinner text='Loading Report...' />
  );
}

function Dashboard({ currStat, getAndSetData }) {
  const { setShowModal, activeCourse, activeAssignment } = useGlobalVars();
  const [selectedStu, setSelectedStu] = useState(null);
  const COLUMNS = [
    {
      id: 's0',
      value: 'Student A',
      width: '15%',
      searchable: true,
      sortable: true,
    },
    {
      id: 'f0',
      value: 'File A',
      width: '15%',
      searchable: true,
      sortable: true,
    },
    {
      id: 'p0',
      value: 'Match % A',
      width: '15%',
      searchable: false,
      sortable: true,
    },
    {
      id: 's1',
      value: 'Student B',
      width: '15%',
      searchable: true,
      sortable: true,
    },
    {
      id: 'f1',
      value: 'File B',
      width: '15%',
      searchable: true,
      sortable: true,
    },
    {
      id: 'p1',
      value: 'Match % B',
      width: '15%',
      searchable: false,
      sortable: true,
    },
    {
      id: 'l',
      value: 'Lines Matched',
      width: '10%',
      searchable: false,
      sortable: true,
    },
  ];

  function makeRow(x) {
    function handleMouseEnter(e) {
      e.target.style.textDecorationLine = 'underline';
    }

    function handleMouseLeave(e) {
      e.target.style.textDecorationLine = '';
    }

    function handleClick(e) {
      e.stopPropagation();
      const stuName = e.target.innerText;
      setSelectedStu(selectedStu === stuName ? null : stuName);
    }

    function getName(s) {
      return title(s.replaceAll('_', ' '));
    }

    return (
      <>
        <td>
          <span
            className={
              selectedStu === getName(x.s0)
                ? 'px-2 bg-highlight border border-2 border-info'
                : ''
            }
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
          >
            {getName(x.s0)}
          </span>
        </td>
        <td>{x.f0}</td>
        <td style={getMatchColor(x.p0)}>{x.p0}</td>
        <td>
          <span
            className={
              selectedStu === getName(x.s1)
                ? 'px-2 bg-highlight border border-2 border-info'
                : ''
            }
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
          >
            {getName(x.s1)}
          </span>
        </td>
        <td>{x.f1}</td>
        <td style={getMatchColor(x.p1)}>{x.p1}</td>
        <td>{x.l}</td>
      </>
    );
  }

  function onClickDownload() {
    const fileName =
      'Code_Inspection-' +
      activeCourse.code +
      '-' +
      activeAssignment.name.slice(
        0,
        Math.min(activeAssignment.name.length, 20),
      ) +
      '-' +
      getDateFromObjectID(currStat._id);

    const cols = ['s0', 'f0', 'p0', 's1', 'f1', 'p1', 'l'];
    const headers = [
      'student_1',
      'file_1',
      'match_%_1',
      'student_2',
      'file_2',
      'match_%_2',
      'lines_matched',
    ];
    downloadCSV(currStat.results, cols, fileName.replaceAll(' ', '_'), headers);
  }

  return (
    <div className='d-flex flex-column h-100'>
      <div className='border rounded bg-white mb-1 p-1'>
        <InputGroup size='md'>
          <InputGroup.Text className='text-primary fw-bold'>
            Choose Inspection to View
            <DocsHelp at={'inspect.code.view_code_inspection'} />
          </InputGroup.Text>
          <Form.Select
            value={currStat._id}
            onChange={(e) => getAndSetData(e.target.value)}
          >
            {currStat.runs &&
              currStat.runs.map((x, i) => (
                <option key={i} value={x}>
                  {getDateFromObjectID(x)}
                </option>
              ))}
          </Form.Select>
          {Array.isArray(currStat.results) && (
            <Button
              variant='info'
              className='text-light'
              onClick={onClickDownload}
            >
              Download as CSV
              <i className='ms-1 bi bi-download' />
            </Button>
          )}
        </InputGroup>
        <div className='pt-1 d-flex small overflow-auto'>
          <span className='flex-fill text-center'>
            <div className='fw-semibold bg-light'>Status</div>
            <div>{title(currStat.status)}</div>
          </span>
          <span className='flex-fill text-center'>
            <div className='fw-semibold bg-light'>Language</div>
            <div>{title(currStat.lang)}</div>
          </span>
          <span className='flex-fill text-center'>
            <div className='fw-semibold bg-light'>Max Matches</div>
            <div>{currStat.max_matches}</div>
          </span>
          <span className='flex-fill text-center'>
            <div className='fw-semibold bg-light'>Max Rows</div>
            <div>{currStat.max_comparisons}</div>
          </span>
          <span className='flex-fill text-center'>
            <div className='fw-semibold bg-light'>Base File</div>
            <div>{currStat.base_code || '—'}</div>
          </span>
        </div>
      </div>
      <div className='flex-grow-1 bg-light border overflow-auto rounded-3'>
        {currStat.results &&
          (Array.isArray(currStat.results) ? (
            <AdvancedTable
              columns={COLUMNS}
              data={currStat.results}
              makeRow={makeRow}
              hover
              onClickRow={(x) =>
                setShowModal(
                  <ReportModal
                    row={x}
                    dt={getDateFromObjectID(currStat._id)}
                  />,
                )
              }
              className='small text-center mb-0'
            />
          ) : (
            <Alert variant='danger' className='m-2 text-center'>
              {currStat.results}
            </Alert>
          ))}
      </div>
    </div>
  );
}

export default memo(CodeReport);
