import { memo, useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { getExtendStats } from '../../api/extend_api.js';
import * as ep from '../plots/ExtendPlots';
import PlotsContainer from '../plots/PlotsContainer';
import * as wrapper from '../plots/Wrapper.js';

function Statistics({ courseId, assignments }) {
  const [currData, setCurrData] = useState([]);
  const [numQuiz, setNumQuiz] = useState(0);
  const [numAssign, setNumAssign] = useState(0);

  useEffect(() => {
    if (assignments === null) return;

    getExtendStats(courseId).then(setCurrData);
    let count = 0;
    for (const a of assignments) {
      if (a.is_quiz) count++;
    }
    setNumQuiz(count);
    setNumAssign(assignments.length - count);
  }, [courseId, assignments]);

  function getTotalStats(data) {
    let totQuizExt = 0;
    let totAssignExt = 0;
    for (const x of data) {
      if (x.days > 0) totAssignExt++;
      if (x.time > 1) totQuizExt++;
    }

    return [
      <wrapper.TotalStats
        key='0'
        title={'Total Students'}
        subtitle={'impacted by Extend'}
        stat={new Set(data.map((x) => x.student_id)).size}
      />,
      <wrapper.TotalStats
        key='1'
        title={'Num Students'}
        subtitle={'with extra days'}
        stat={totAssignExt}
      />,
      <wrapper.TotalStats
        key='2'
        title={'Num Students'}
        subtitle={'with extra time'}
        stat={totQuizExt}
      />,
      <wrapper.TotalStats
        key='3'
        title={'Total Extensions'}
        subtitle={'Extend has set'}
        stat={totAssignExt * numAssign + totQuizExt * numQuiz}
      />,
      <wrapper.TotalStats
        key='4'
        title={'Num Extensions'}
        subtitle={'set for assignments'}
        stat={totAssignExt * numAssign}
      />,
      <wrapper.TotalStats
        key='5'
        title={'Num Extensions'}
        subtitle={'set for quizzes'}
        stat={totQuizExt * numQuiz}
      />,
    ];
  }

  function GeneralStatisticsHelper() {
    const stats = getTotalStats(currData);
    return <ListGroup variant='flush'>{stats.map((x) => x)}</ListGroup>;
  }

  function plotsData() {
    return [
      {
        title: 'Extra Days Distribution',
        element: <ep.ExtraDaysDistribution data={currData} />,
        display: true,
      },
      {
        title: 'Extra Time Distribution',
        element: <ep.ExtraTimeDistribution data={currData} />,
        display: true,
      },
    ];
  }

  return (
    <wrapper.Wrapper ifNull={currData === null}>
      <></>
      <wrapper.GeneralStatistics child={<GeneralStatisticsHelper />} />
      <></>
      <PlotsContainer data={plotsData()} />
    </wrapper.Wrapper>
  );
}

export default memo(Statistics);
