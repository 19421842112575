import { memo, useEffect, useState } from 'react';
import { Alert, Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalVars } from '../../../App';
import * as grade_api from '../../api/grade_api';
import confirmExit from '../../util/confirm_exit';
import { deepCompare } from '../../util/deep_compare';
import { deepCopy } from '../../util/deep_copy';
import { title } from '../../util/string_methods';
import Confirm from '../reusable/Confirm';
import ExpandableCard from '../reusable/ExpandableCard';
import * as constants from './Constants';
import GeneralConfig from './GeneralConfig';
import GradeLogs from './GradeLogs';
import Test from './Test';
import Trigger from './Trigger';

const success_icon = <i className='bi bi-check-circle-fill text-success' />;
const failure_icon = (
  <i className='bi bi-exclamation-circle-fill text-danger' />
);

export function getStatusTD(success) {
  return (
    <td className='fs-5 text-center'>
      {success ? success_icon : failure_icon}
    </td>
  );
}

function AssignmentGrade({
  exists,
  assignmentID,
  assignmentName,
  courseId,
  courseCode,
  students,
  setViewChange,
  teachers,
}) {
  const [config, setConfig] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [logsData, setLogsData] = useState(null);
  const [formData, setFormData] = useState(null);
  const [editState, setEditState] = useState(null);
  const [validated, setValidated] = useState(false);
  const [validDays, setValidDays] = useState(false);
  const [fixErrorsMsg, setFixErrorsMsg] = useState('');
  const [tasks, setTasks] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const prevPage = location.pathname.match(/[\w\d:/\-~]*grade/g);
  const { setActiveAssignment, setShowModal, userId } = useGlobalVars();

  confirmExit(
    'You have unsaved changes. Are you sure you want to leave?',
    editState !== null,
  );

  useEffect(() => {
    setActiveAssignment({ id: assignmentID, name: assignmentName });

    if (exists) grade_api.getGrade(courseId, assignmentID).then(setConfig);
    else {
      setFormData({
        ...constants.DEFAULT_CONFIG,
        user_id: userId,
        _id: {
          course_id: Number(courseId),
          assignment_id: Number(assignmentID),
        },
      });
      let defTasks = { ...constants.DEFAULT_TASKS };
      const courseVal = nameToPath(courseCode, 17);
      const assignVal = nameToPath(assignmentName, 17);
      defTasks.run.path = `~/ramdesk/${courseVal}/${assignVal}`;
      setTasks({ ...defTasks });
      setEditState(true);
    }
  }, [assignmentID]);

  useEffect(() => {
    if (config !== null) constructFormData();
  }, [config]);

  const constructFormData = (from = config, overwriteFile = true) => {
    setFormData(from);
    setTasks({ ...from.tasks });
    if (overwriteFile)
      from?.file ? setFileData({ name: from?.file }) : setFileData(null);
  };

  function nameToPath(name, maxLength) {
    return name
      .replace(/\s|-/g, '_')
      .replace(/\W+/g, '')
      .replace(/_+/g, '_')
      .slice(0, maxLength);
  }

  const handleReturnToDashboard = () => {
    setViewChange((prev) => !prev);
    if (prevPage.length === 0) navigate('/courses');
    else navigate(prevPage[0]);
  };

  const handleDeleteConfig = () => {
    setShowModal(
      <Confirm
        question='Are you sure you want to delete this autograder?'
        onConfirm={() => {
          grade_api
            .deleteGrade(config)
            .then(() => {
              setFormData(null);
              setTasks(null);
              setConfig(null);
              setFileData(null);
              handleReturnToDashboard();
              toast.success('Successfully deleted autograder!');
            })
            .catch(() => toast.error('Failed to delete autograder!'));
        }}
      />,
    );
  };

  const reset = () => {
    setEditState(null);
    setValidDays(false);
    setFixErrorsMsg('');
    setValidated(false);
  };

  const handleSaveEdit = (e) => {
    e.preventDefault();

    if (
      formData.schedule.type === 'schedule' &&
      !Object.values(formData.schedule.days).some((value) => value)
    ) {
      setValidDays(true);
      return;
    }
    if (tasks.run.cmd === '' && tasks.run.type === 'custom') {
      setFixErrorsMsg(`Command for Run Grader must not be empty!`);
      return;
    }

    if (tasks.run.location === 'ssh' && !formData.networking) {
      setFixErrorsMsg(
        'You must first enable networking if you want to use the ssh feature!',
      );
      return;
    }
    if (tasks.run.host === 'localhost' || tasks.run.host === '127.0.0.1') {
      setFixErrorsMsg('Host cannot contain localhost or 127.0.0.1');
      return;
    }

    if (tasks.check_files.enabled) {
      if (
        tasks.check_files.required.length === 0 &&
        tasks.check_files.allowed.length === 0
      ) {
        setFixErrorsMsg(
          'You must enter either allowed or required files when check files is enabled!',
        );
        return;
      }
    }

    if (tasks.check_code.enabled) {
      if (
        tasks.check_code.required.length === 0 &&
        tasks.check_code.forbidden.length === 0
      ) {
        setFixErrorsMsg(
          'You must enter either required or forbidden code when check code is enabled.',
        );
        return;
      }
    }

    if (e.currentTarget.checkValidity() === false) {
      setValidated(true);
      e.stopPropagation();
    } else {
      const payload = deepCopy({ ...formData, tasks: tasks });
      if (config) {
        grade_api
          .updateGrade(payload, fileData)
          .then(() => {
            setConfig({ ...payload, file: fileData?.name });
            reset();
            toast.success('Updated autograder successfully!');
          })
          .catch(() => toast.error('Failed to update autograder!'));
      } else {
        grade_api
          .addGrade(payload, fileData)
          .then(() => {
            setConfig({ ...payload, file: fileData?.name });
            reset();
            toast.success('Added new autograder successfully!');
          })
          .catch(() => toast.error('Failed to add new autograder!'));
      }
    }
  };

  const handleCancelEdit = (e) => {
    e.preventDefault();
    if (config !== null) {
      constructFormData();
    } else {
      setFormData(null);
      setTasks(null);
      setFileData(null);
      handleReturnToDashboard();
    }
    reset();
  };

  const handleFileDownload = (e) => {
    e.preventDefault();
    grade_api
      .downloadGrade(courseId, assignmentID)
      .catch(() => toast.error('Failed to retrieve the grade file.'));
  };

  async function copyData() {
    try {
      const objToCopy = deepCopy({ ...formData, tasks: tasks });
      if (objToCopy.tasks.run.location !== 'ssh') {
        objToCopy.tasks.run.user = null;
        objToCopy.tasks.run.password = null;
        objToCopy.tasks.run.host = null;
        objToCopy.tasks.run.path = null;
      }

      await navigator.clipboard.writeText(JSON.stringify(objToCopy, null, 2));
      toast.success('Successfully copied grader data to clipboard!');
    } catch (err) {
      toast.error('Something went wrong when copying: ' + err.name);
    }
  }

  async function pasteData() {
    try {
      let pastedData = await navigator.clipboard.readText();
      pastedData = JSON.parse(pastedData);

      pastedData._id = {
        course_id: Number(courseId),
        assignment_id: Number(assignmentID),
      };
      pastedData.tasks.run.user = '';
      pastedData.tasks.run.password = '';
      pastedData.tasks.run.host = '';
      pastedData.tasks.run.path = '';

      const oldObj = { ...formData, tasks: tasks };
      const { modifiedObj, keysPresent, keysAbsent } = deepCompare(
        pastedData,
        oldObj,
      );
      setShowModal(
        <Confirm
          question={
            <div className='small'>
              <div>Are you sure? These values will be overwritten.</div>
              <div
                style={{ maxHeight: '160px' }}
                className='overflow-auto border rounded p-2 my-1'
              >
                {keysPresent.map((x, i) => {
                  if (x.includes('id')) return;
                  return <div key={i}>{title(x.replaceAll('.', ' > '))}</div>;
                })}
              </div>
              {keysAbsent.length > 0 && (
                <Alert variant='danger' className='p-2 mt-3 mb-0'>
                  <div>
                    These values will be ignored as they were absent from the
                    pasted data:
                  </div>
                  <div
                    style={{ maxHeight: '120px' }}
                    className='overflow-auto my-1'
                  >
                    {keysAbsent.map((x, i) => (
                      <div key={i}>{title(x.replaceAll('.', ' > '))}</div>
                    ))}
                  </div>
                </Alert>
              )}
            </div>
          }
          onConfirm={() => {
            constructFormData(modifiedObj, false);
            toast.success('Successfully populated grade configuration!');
          }}
        />,
      );
    } catch (err) {
      if (err instanceof SyntaxError)
        toast.error('Failed to paste - invalid data!');
      else toast.error(err.message);
    }
  }

  return (
    <div className='pb-3'>
      {config !== null && (
        <ExpandableCard open={true}>
          <div className='flex-grow-1'>Logs</div>
          <div className='m-1'>
            <GradeLogs
              courseId={courseId}
              assignmentId={assignmentID}
              students={students}
              logsData={logsData}
              setLogsData={setLogsData}
            />
          </div>
        </ExpandableCard>
      )}
      {formData && (
        <ExpandableCard open={editState === true}>
          <>
            <div className='flex-grow-1'>Configuration</div>
            {formData.active ? (
              <div>
                Active
                <i className='bi bi-check-circle-fill ms-2'></i>
              </div>
            ) : (
              <div>
                Inactive
                <i className='bi bi-exclamation-circle-fill ms-2'></i>
              </div>
            )}
          </>
          <Form
            onSubmit={handleSaveEdit}
            noValidate
            validated={validated}
            className='p-3'
          >
            <GeneralConfig
              formData={formData}
              setFormData={setFormData}
              tasks={tasks}
              setTasks={setTasks}
              editState={editState}
              fileData={fileData}
              setFileData={setFileData}
              validDays={validDays}
              handleFileDownload={handleFileDownload}
              teachers={teachers}
            />
            <br />
            <div className='text-end border-top'>
              <span className='text-danger small'>{fixErrorsMsg}</span>
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip>Copy Grade Configuration to Clipboard</Tooltip>
                }
              >
                <Button
                  className='m-1'
                  variant='light'
                  size='md'
                  onClick={() => copyData()}
                >
                  <i className='bi bi-copy'></i>
                </Button>
              </OverlayTrigger>
              {editState === null ? (
                <>
                  <Button
                    className='m-1 text-light'
                    variant='info'
                    size='md'
                    onClick={() => setEditState(true)}
                  >
                    <i className='bi bi-pencil-fill'></i> Edit
                  </Button>
                  <Button
                    className='m-1 text-light'
                    variant='danger'
                    size='md'
                    onClick={handleDeleteConfig}
                  >
                    <i className='bi bi-trash-fill'></i> Delete
                  </Button>
                </>
              ) : (
                <>
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip>
                        Paste Grade Configuration from Clipboard
                      </Tooltip>
                    }
                  >
                    <Button
                      className='m-1'
                      variant='light'
                      size='md'
                      onClick={() => pasteData()}
                    >
                      <i className='bi bi-clipboard'></i>
                    </Button>
                  </OverlayTrigger>
                  <Button
                    className='m-1 text-light'
                    variant='info'
                    size='md'
                    type={'submit'}
                  >
                    <i className='bi bi-floppy-fill'></i> Save
                  </Button>
                  <Button
                    className='m-1 text-light'
                    variant='danger'
                    size='md'
                    onClick={handleCancelEdit}
                  >
                    <i className='bi bi-ban'></i> Cancel
                  </Button>
                </>
              )}
            </div>
          </Form>
        </ExpandableCard>
      )}
      {config !== null && (
        <>
          <ExpandableCard>
            <div className='flex-grow-1'>Test</div>
            <div>
              <Test courseId={courseId} assignmentId={assignmentID} />
            </div>
          </ExpandableCard>
          <ExpandableCard>
            <div className='flex-grow-1'>Trigger</div>
            <div>
              <Trigger
                courseId={courseId}
                assignmentId={assignmentID}
                students={students}
              />
            </div>
          </ExpandableCard>
        </>
      )}
    </div>
  );
}

export default memo(AssignmentGrade);
