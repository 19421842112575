import { memo } from 'react';
import { Button, Col, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useGlobalVars } from '../../../App';
import { stopInspection } from '../../api/inspect_api';
import { gradient } from '../../util/colors';
import { title } from '../../util/string_methods';
import { DocsHelp } from '../docs/Docs';
import AdvancedTable from '../reusable/AdvancedTable';
import Confirm from '../reusable/Confirm';
import { GRADIENT_COLORS } from './Constants';
import { CodeRunForm, TextRunForm } from './RunModals';

export function getMatchColor(hs) {
  const style = {
    backgroundColor: gradient(GRADIENT_COLORS, hs),
  };

  return isNaN(parseInt(hs)) ? {} : style;
}

function Dashboard({
  courseId,
  configStats,
  setViewChange,
  selectedTool,
  setSelectedTool,
}) {
  const navigate = useNavigate();
  const { setShowModal } = useGlobalVars();

  const COLUMNS = [
    {
      id: 'name',
      value: 'Name',
      headerStyle: 'ps-3 text-start',
      width: '30%',
      searchable: true,
      sortable: true,
    },
    {
      id: 'status',
      value: 'Status',
      width: '15%',
      searchable: false,
      sortable: true,
    },
    {
      id: 'timestamp',
      value: 'Ran at',
      width: '20%',
      searchable: false,
      sortable: true,
    },
    {
      id: 'highestScore',
      value: selectedTool === 'code' ? 'Highest Match %' : 'Highest AI %',
      width: '10%',
      searchable: false,
      sortable: true,
    },
    {
      id: 'actions',
      value: 'Actions',
      width: '25%',
      searchable: false,
      sortable: false,
    },
  ];

  function handleCancel(asgId) {
    setShowModal(
      <Confirm
        question={`Are you sure you want to cancel this ${selectedTool} inspection?`}
        onConfirm={() => {
          stopInspection(courseId, asgId, selectedTool)
            .then(() => {
              toast.success('Successfully canceled task!');
              setViewChange((prev) => !prev);
            })
            .catch(() => toast.error('Failed to cancel this task!'));
        }}
      />,
    );
  }

  function handleRun(stat) {
    if (selectedTool === 'code')
      setShowModal(
        <CodeRunForm
          courseId={courseId}
          stat={stat}
          setViewChange={setViewChange}
        />,
      );
    else if (selectedTool === 'text')
      setShowModal(
        <TextRunForm
          courseId={courseId}
          stat={stat}
          setViewChange={setViewChange}
        />,
      );
  }

  function getStatusTD(status) {
    switch (status) {
      case 'completed':
        return <i className='bi bi-check-circle-fill text-success' />;
      case 'running':
      case 'queued':
        return <Spinner size='sm' variant='info' />;
      case 'failed':
        return <i className='bi bi-x-circle-fill text-danger' />;
    }
  }

  function makeRow(stat) {
    return (
      <>
        <td className='ps-3 fw-semibold text-start'>{stat.name}</td>
        <td className='vertical-middle'>{getStatusTD(stat.status)}</td>
        <td>{stat.timestamp && stat.timestamp.toLocaleString()}</td>
        <td style={getMatchColor(stat.highestScore)}>{stat.highestScore}</td>
        <td className='p-0 vertical-middle'>
          {stat.status === 'running' || stat.status === 'queued' ? (
            <span onClick={(e) => e.stopPropagation()}>
              <Button
                variant='danger'
                size='sm'
                style={{ width: '20ch' }}
                className='text-light'
                onClick={() => handleCancel(stat.assignment_id)}
              >
                <i className='bi bi-x-lg me-1' />
                Cancel
              </Button>
            </span>
          ) : stat.status === 'completed' ? (
            <span onClick={(e) => e.stopPropagation()}>
              <Button
                variant='info'
                size='sm'
                style={{ width: '20ch' }}
                className='text-light'
                onClick={() => handleRun(stat)}
              >
                <i className='bi bi-arrow-repeat me-1' />
                {`Reinspect ${title(selectedTool)}`}
              </Button>
            </span>
          ) : (
            <span onClick={(e) => e.stopPropagation()}>
              <Button
                variant='info'
                size='sm'
                style={{ width: '20ch' }}
                className='text-light'
                onClick={() => handleRun(stat)}
              >
                <i className='bi bi-gear-fill me-1' />
                {`Inspect ${title(selectedTool)}`}
              </Button>
            </span>
          )}
        </td>
      </>
    );
  }

  function handleOnClick(asg) {
    if (!asg?.results) return;
    navigate(`${selectedTool}/${asg.assignment_id}`);
  }

  return (
    <div className='d-flex flex-column h-100'>
      <Row className='m-0'>
        <Col xs={6} className='d-md-block d-none py-1 px-0'>
          <div className='ps-2 h-100 d-flex align-items-center bg-light w-100 fw-semibold rounded border truncateTxt'>
            Inspect Dashboard
            <DocsHelp at='inspect.dashboard' />
          </div>
        </Col>
        <Col xs={12} md={6} className='px-2 inspect-toggle'>
          <Tabs
            fill
            activeKey={selectedTool}
            onSelect={(e) => setSelectedTool(e)}
          >
            <Tab
              eventKey={'code'}
              title={
                <>
                  <i
                    className={`bi bi-file-earmark-code${selectedTool === 'code' ? '-fill' : ''}`}
                  />
                  {' Code'}
                </>
              }
            />
            <Tab
              eventKey={'text'}
              title={
                <>
                  <i
                    className={`bi bi-file-earmark-font${selectedTool === 'text' ? '-fill' : ''}`}
                  />
                  {' Text'}
                </>
              }
            />
          </Tabs>
        </Col>
      </Row>
      <div className='flex-grow-1 border bg-light overflow-auto rounded-3'>
        {configStats && (
          <AdvancedTable
            columns={COLUMNS}
            data={configStats}
            makeRow={makeRow}
            hover
            setClickable={(x) =>
              x.status === 'completed' || x.status === 'failed'
            }
            onClickRow={handleOnClick}
            className='small text-center mb-0'
          />
        )}
      </div>
    </div>
  );
}

export default memo(Dashboard);
