export const LANGS = [
  'a8086',
  'ada',
  'ascii',
  'c',
  'c++',
  'c#',
  'fortran',
  'haskell',
  'java',
  'javascript',
  'lisp',
  'matlab',
  'mips',
  'ml',
  'modula2',
  'pascal',
  'perl',
  'plsql',
  'prolog',
  'python',
  'scheme',
  'spice',
  'vb',
  'verilog',
  'vhdl',
];

export const GRADIENT_COLORS = [
  { color: '#e56a54', value: 90 },
  { color: '#ffc038', value: 60 },
  { color: '#82c503', value: 30 },
];

export const CODE_COLORS = [
  'lavender',
  'lightgreen',
  'lightpink',
  'lightblue',
  'lightcoral',
  'lightsalmon',
  'lemonchiffon',
  'lightskyblue',
  'tan',
  'mistyrose',
];
