import { memo, useState } from 'react';
import { Col, Form, ListGroup } from 'react-bootstrap';
import { DocsHelp } from '../docs/Docs';
import { SOFTWARE } from './Constants';

function SoftwareVersions({ formData, setFormData }) {
  const [allVersions, setAllVersions] = useState(false);

  return (
    <>
      <Form.Label>
        Software Versions
        <DocsHelp at={'grade.config.versions'} />
      </Form.Label>
      <Col xs={12} md={8} xl={6}>
        <ListGroup>
          {SOFTWARE.map((x, index) => {
            if (!allVersions && index > 4) return;
            const lcName = x.name.toLowerCase();
            return (
              <ListGroup.Item
                key={index}
                variant='light'
                className='d-flex py-1 mb-1 overflow-auto'
              >
                <div className='w-25 border-end fw-semibold'>{x.name}</div>
                {x.versions === null ? (
                  <div className='ms-2'>Latest</div>
                ) : (
                  x.versions.map((v) => (
                    <Form.Check
                      className='ms-2'
                      style={{ width: 70 }}
                      key={v}
                      type='radio'
                      id={`${v}Version`}
                      name={`${x.name}Version`}
                      label={v == null ? 'None' : v}
                      checked={formData.versions[lcName] === v}
                      onChange={() =>
                        setFormData((prev) => ({
                          ...prev,
                          ['versions']: {
                            ...prev['versions'],
                            [lcName]: v,
                          },
                        }))
                      }
                    />
                  ))
                )}
              </ListGroup.Item>
            );
          })}
          <ListGroup.Item
            as={'button'}
            className='p-0 text-center'
            onClick={(e) => {
              e.preventDefault();
              setAllVersions(!allVersions);
            }}
          >
            <i
              className={`bi bi-chevron-double-${allVersions ? 'up' : 'down'}`}
            />
          </ListGroup.Item>
        </ListGroup>
      </Col>
    </>
  );
}

export default memo(SoftwareVersions);
