export const DAYS_LONG = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const DAYS_SHORT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export function toLocalTZ(date) {
  return new Date(date).toLocaleString();
}

export function secondsToTime(totalSeconds) {
  if (
    totalSeconds === null ||
    totalSeconds === undefined ||
    !Number.isInteger(totalSeconds)
  )
    return 'Unknown';
  const seconds = totalSeconds % 60;
  const totalMinutes = Math.floor(totalSeconds / 60);
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);
  return `${hours}h:${String(minutes).padStart(2, '0')}m:${String(seconds).padStart(2, '0')}s`;
}

export function getDateFromObjectID(id) {
  const dt = id.substring(0, 8);
  const timestamp = parseInt(dt, 16);
  return new Date(timestamp * 1000).toLocaleString();
}
