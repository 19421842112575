import { memo, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
  padding: '20px',
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: 'gray',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  cursor: 'pointer',
  overflowY: 'hidden',
  height: '100%',
};

function FileSelector({
  fileData,
  setFileData,
  maxFiles = 1,
  multiple = false,
  dndStyle = baseStyle,
}) {
  function handleOnDrop(acceptedFiles) {
    if (multiple) setFileData(acceptedFiles);
    else setFileData(acceptedFiles[0]);
  }

  const { fileRejections, getRootProps, getInputProps, isDragAccept } =
    useDropzone({
      noKeyboard: true,
      maxSize: 2 ** (20 ** 15),
      onDrop: handleOnDrop,
      maxFiles: maxFiles,
      multiple: multiple,
    });

  const style = useMemo(
    () => ({
      ...dndStyle,
      ...(isDragAccept ? { borderColor: '#1e4d2b' } : {}),
    }),
    [isDragAccept],
  );

  const rejectedItems = fileRejections.map(({ file, errors }) => (
    <p className='text-danger small' key={file.name}>
      {`Error uploading ${file.name} : ${errors[0].code}`}
    </p>
  ));

  return (
    <div className='flex-grow-1'>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {fileData === null ? (
          <span>
            {multiple
              ? 'Drag & drop files here, or click to select files...'
              : 'Drag & drop a file here, or click to select a file...'}
            {rejectedItems}
          </span>
        ) : multiple ? (
          <span>Uploaded Files: {fileData.length}</span>
        ) : (
          <span>Uploaded File: {fileData?.name}</span>
        )}
      </div>
    </div>
  );
}

export default memo(FileSelector);
