export function Home() {
  return (
    <div className='small'>
      <h3 className='text-center fw-bold text-primary'>Welcome to RamDesk!</h3>
      <hr />
      <p>
        RamDesk is a tool meant to be used by instructors and TAs for
        convenience of adding extensions to students, auto grading assignments,
        and sending personalized reminders to students regarding assignment
        deadline and performance in course. There might be more in the future,
        but currently, we support these three tools: Extend, Grade, and Nudge.
      </p>
      <p>
        To explore more about these tools, you can learn more about them through
        the sidebar to the left or by using the actual tool. Please be cautious
        as modifying something could affect the course you select, for example,
        adding extensions using Extend will immediately start putting those
        extensions on Canvas.
      </p>
    </div>
  );
}

export function Breadcrumbs() {
  return (
    <div className='small'>
      <p>
        The breadcrumbs are a way to navigate through the RamDesk interface. You
        can click on any of the links to go back to that page. The last link is
        the current page you are on. The breadcrumbs are also used to change the
        title of the page, which is displayed on the tab of the browser.
      </p>
      <p>
        These are located on the top of the page next to the CSU logo. They will
        always start with RamDesk, which will take you to the login page if you
        are not logged in and to the courses page if you are logged in.
      </p>
    </div>
  );
}

export function Courses() {
  return (
    <div className='small'>
      <p>
        In the courses page, you will see a list of the courses for which you
        are either a TA, Teaching Assistant, or Teacher. This means that a
        student cannot access any of their own courses. Once, you select a
        course, you will see more options that denote each of the tools
        avaliable on RamDesk. To learn more about these, navigate to the Course
        section or the sections about the tools.
      </p>
      <p>
        If you are unable to see a course in the list that you feel like you
        should see, please verify that your role in the course is one of the
        three listed above. If you are a Designer for example, you will not see
        any courses.
      </p>
    </div>
  );
}

export function Course() {
  return (
    <div className='small'>
      <p>
        After selecting a course, you will see some options, each for one of the
        tools. You can select any tool and start interacting with it. If you
        require help, you can click on the About button located on each of the
        cards to learn more about the tool.
      </p>
      <p>
        Each tool also has a Statistics page where you can get a summary of how
        you have set up the tool and how many students are affected by it. For
        example, in some plots, you are able to see when students submit their
        assignments or how many students have received what types of extensions.
      </p>
    </div>
  );
}

export function Unsubscribe() {
  return (
    <div className='small'>
      When using the Nudge tool, you can configure a rule so that a student is
      able to unsubscribe from receiving any emails. If set to true, the message
      will include a link to a webpage to unsubscribe, which will bring them to
      this page. Thereafter, they will no longer receive any emails for that
      rule only, meaning that if a student is included in multiple rules for a
      course or many courses, they will only stop receiving emails for that one
      single rule.
    </div>
  );
}

export function BugReport() {
  return (
    <div className='small'>
      If you have any problems while using RamDesk, have any suggestions, or
      want to request a feature, you can use this tool to do so. This will be
      sent to the RamDesk team and we will try to answer back or fix the problem
      as soon as possible.
    </div>
  );
}
