import { useState } from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  InputGroup,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useGlobalVars } from '../../../App';
import {
  startCodeInspection,
  startTextInspection,
} from '../../api/inspect_api';
import { title } from '../../util/string_methods';
import FileSelector from '../reusable/FileSelector';
import { LANGS } from './Constants';

function RunFooter() {
  const { setShowModal } = useGlobalVars();
  return (
    <div className='d-flex align-items-center justify-content-end border-top p-1 gap-2'>
      <Button
        className={'text-light'}
        variant={'danger'}
        onClick={() => {
          setShowModal(null);
        }}
      >
        Cancel
      </Button>
      <Button className={'text-light'} variant={'info'} type='submit'>
        Start
      </Button>
    </div>
  );
}

export function CodeRunForm({ courseId, stat, setViewChange }) {
  const [formData, setFormData] = useState({
    course_id: courseId,
    assignment_id: stat.assignment_id,
    lang: stat?.lang || 'java',
    max_comparisons: stat?.max_comparisons || 100,
    max_matches: stat?.max_matches || 10,
    dir: false,
  });
  const [fileData, setFileData] = useState(
    stat?.base_code ? { name: stat?.base_code } : null,
  );
  const [validated, setValidated] = useState(false);
  const { setShowModal } = useGlobalVars();

  function handleSubmit(e) {
    e.preventDefault();

    if (e.currentTarget.checkValidity() === false) {
      setValidated(true);
      e.stopPropagation();
    } else {
      startCodeInspection(courseId, stat.assignment_id, formData, fileData)
        .then(() => {
          toast.success(`Successfully started code inspection!`);
          setShowModal(null);
          setViewChange((prev) => !prev);
        })
        .catch(() => toast.error(`Failed to start code inspection!`));
    }
  }

  return (
    <Modal size='lg' show centered onHide={() => setShowModal(null)}>
      <Modal.Header className='fw-semibold p-2 bg-primary text-light'>
        Run Code Analysis
      </Modal.Header>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body as={Row} className='p-0 m-0'>
          <Col xs={12} lg={6} className='p-3'>
            <InputGroup size='sm'>
              <InputGroup.Text style={{ width: '180px' }}>
                Programming Language
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip>
                      The language that the submissions are written in.
                    </Tooltip>
                  }
                >
                  <i className='bi bi-question-circle-fill text-info ms-1' />
                </OverlayTrigger>
              </InputGroup.Text>
              <Form.Select
                value={formData.lang}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, lang: e.target.value }))
                }
              >
                {LANGS.map((x, i) => (
                  <option value={x} key={i}>
                    {title(x)}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>
            <InputGroup size='sm' hasValidation className='my-2'>
              <InputGroup.Text style={{ width: '180px' }}>
                Max Matches
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip>
                      Sets the maximum number of times a given passage may
                      appear before it is ignored.
                    </Tooltip>
                  }
                >
                  <i className='bi bi-question-circle-fill text-info ms-1' />
                </OverlayTrigger>
              </InputGroup.Text>
              <Form.Control
                type='number'
                max={100}
                min={1}
                required
                value={formData.max_matches}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    max_matches: parseInt(e.target.value),
                  })
                }
              />
              <Form.Control.Feedback type='invalid' tooltip>
                {'Number must be > 1 and < 100'}
              </Form.Control.Feedback>
            </InputGroup>
            <InputGroup size='sm' hasValidation>
              <InputGroup.Text style={{ width: '180px' }}>
                Max Rows
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip>
                      Determines the number of matching files to show in the
                      results.
                    </Tooltip>
                  }
                >
                  <i className='bi bi-question-circle-fill text-info ms-1' />
                </OverlayTrigger>
              </InputGroup.Text>
              <Form.Control
                type='number'
                max={300}
                min={1}
                required
                value={formData.max_comparisons}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    max_comparisons: parseInt(e.target.value),
                  })
                }
              />
              <Form.Control.Feedback type='invalid' tooltip>
                {'Number must be > 1 and < 350'}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
          <Col xs={12} lg={6} className='p-1'>
            <div className='d-flex flex-column h-100 p-1 border rounded'>
              <Form.Label>
                Base File(s)
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip>
                      Matches with the content of the file(s) will be ignored.
                      Could be instructor-supplied code. Upload a single file or
                      a zip if multiple.
                    </Tooltip>
                  }
                >
                  <i className='bi bi-question-circle-fill text-info ms-1' />
                </OverlayTrigger>
              </Form.Label>
              {fileData === null ? (
                <FileSelector fileData={fileData} setFileData={setFileData} />
              ) : (
                <ButtonGroup className='w-100 h-100 border overflow-hidden'>
                  <Button
                    variant='light'
                    className='w-90 truncateTxt text-center'
                    disabled
                  >
                    {fileData.name}
                  </Button>
                  <Button variant='light' onClick={() => setFileData(null)}>
                    <i className={'bi bi-trash-fill text-danger'}></i>
                  </Button>
                </ButtonGroup>
              )}
            </div>
          </Col>
          <RunFooter />
        </Modal.Body>
      </Form>
    </Modal>
  );
}

export function TextRunForm({ courseId, stat, setViewChange }) {
  const [validated, setValidated] = useState(false);
  const { setShowModal } = useGlobalVars();

  function handleSubmit(e) {
    e.preventDefault();

    if (e.currentTarget.checkValidity() === false) {
      setValidated(true);
      e.stopPropagation();
    } else {
      startTextInspection(courseId, stat.assignment_id, {
        course_id: courseId,
        assignment_id: stat.assignment_id,
      })
        .then(() => {
          toast.success(`Successfully started text inspection!`);
          setShowModal(null);
          setViewChange((prev) => !prev);
        })
        .catch(() => toast.error(`Failed to start text inspection!`));
    }
  }

  return (
    <Modal size='lg' show centered onHide={() => setShowModal(null)}>
      <Modal.Header className='fw-semibold p-2 bg-primary text-light'>
        Run Text Analysis
      </Modal.Header>
      <Modal.Body className='p-0'>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className='p-3'>
            Are you sure you want to start a text inspection for this
            assignment?
          </div>
          <RunFooter />
        </Form>
      </Modal.Body>
    </Modal>
  );
}
