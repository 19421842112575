import { memo, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  OverlayTrigger,
  Placeholder,
  Row,
  Tooltip,
} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalVars } from '../../../App';
import { addRule, deleteRule, updateRule } from '../../api/nudge_api';
import confirmExit from '../../util/confirm_exit';
import { deepCompare } from '../../util/deep_compare';
import { title } from '../../util/string_methods';
import Confirm from '../reusable/Confirm';
import { KW_RE, NUDGE_KWS } from './Constants';
import MessageConfig from './MessageConfig';
import RuleConfig from './RuleConfig';

function getUniqueName(startName, allNames) {
  let avaliableName = startName;
  if (avaliableName === null) {
    for (let i = 1; i < allNames.length + 2; i++) {
      if (!allNames.includes(`Nudge_${i}`)) {
        avaliableName = `Nudge_${i}`;
        break;
      }
    }
  }
  return avaliableName;
}

function RuleCard({
  courseId,
  entry,
  rules,
  students,
  assignments,
  setViewChange,
}) {
  const VALID_NAME = /[^a-zA-Z0-9\-_.~]/g;
  const allNames = rules.map((x) => x.name);
  const [origData, setOrigData] = useState(entry);
  const [rule, setRule] = useState({ ...entry });
  const [ruleName, setRuleName] = useState(getUniqueName(entry.name, allNames));
  const [validated, setValidated] = useState(false);
  const [invalidName, setInvalidName] = useState('');
  const [validDays, setValidDays] = useState(true);
  const [stuIsValid, setStuIsValid] = useState(true);
  const [asgIsValid, setAsgIsValid] = useState(true);
  const [fixErrorsMsg, setFixErrorsMsg] = useState('');
  const [display, setDisplay] = useState(6);
  const navigate = useNavigate();
  const location = useLocation();
  const prevPage = location.pathname.match(/[\w\d:/\-~]*nudge/g);
  const { setShowModal } = useGlobalVars();

  useEffect(() => {
    let msg = '';
    const invalidChars = ruleName.match(VALID_NAME) || [];
    if (!ruleName) msg += 'Rule must have a name!\n';
    if (entry.name !== ruleName && allNames.includes(ruleName))
      msg += 'Name must be unique!\n';
    if (ruleName.length > 40) msg += 'Name must be <40 characters!\n';
    if (invalidChars.length > 0)
      msg += `Name contains invalid characters: ${JSON.stringify(invalidChars)}\n`;
    setInvalidName(msg);
  }, [ruleName]);

  confirmExit(
    'You have unsaved changes. Are you sure you want to leave?',
    JSON.stringify(origData) !== JSON.stringify({ ...rule, name: ruleName }),
  );

  function checkMsgIsValid(text) {
    if (!rule.active) return 'ok';
    const strBody = String(text);
    const allowed_kw = NUDGE_KWS.map((kw) => kw.id);
    if (strBody.length < 1) return 'Required!';

    const arr = strBody.matchAll(KW_RE);

    for (const x of arr) {
      if (!allowed_kw.includes(x[1].toLowerCase()))
        return `${x[1]} is not a valid keyword!`;
    }
    return 'ok';
  }

  function handleReturnToDashboard() {
    setViewChange((prev) => !prev);
    if (prevPage.length === 0) navigate('/courses');
    else navigate(prevPage[0]);
  }

  function reset() {
    setValidDays(true);
    setStuIsValid(true);
    setAsgIsValid(true);
    setFixErrorsMsg('');
    setValidated(false);
  }

  function onAdd() {
    const newRule = { ...rule, name: ruleName };
    addRule(courseId, newRule, assignments.length, students.length)
      .then(() => {
        setOrigData(newRule);
        reset();
        toast.success('Added new rule successfully!');
      })
      .catch(() => toast.error('Failed to add new rule!'));
  }

  function onUpdate() {
    updateRule(
      courseId,
      ruleName,
      { ...rule },
      assignments.length,
      students.length,
    )
      .then(() => {
        setOrigData({ ...rule });
        reset();
        toast.success('Updated rule successfully!');
      })
      .catch(() => toast.error('Failed to update rule!'));
  }

  function onDeleteThenAdd() {
    deleteRule(courseId, origData.name)
      .then(() => {
        const newRule = { ...rule, name: ruleName };
        addRule(courseId, newRule, assignments.length, students.length)
          .then(() => {
            setOrigData(newRule);
            reset();
            toast.success('Updated rule successfully!');
          })
          .catch(() => toast.error('Failed to update rule!'));
      })
      .catch(() => toast.error('Failed to update rule!'));
  }

  function onSave(e) {
    e.preventDefault();

    if (invalidName !== '') return;
    if (!rule.days_to_send_emails.some((value) => value)) {
      setValidDays(false);
      setFixErrorsMsg(`Days to Send Message: Required!`);
      return;
    } else setValidDays(true);
    if (rule.assignments_to_include.length === 0) {
      setAsgIsValid(false);
      setFixErrorsMsg(`Assignment to Include: Required!`);
      return;
    } else setAsgIsValid(true);
    if (rule.students_to_include.length === 0) {
      setStuIsValid(false);
      setFixErrorsMsg(`Students to Include: Required!`);
      return;
    } else setStuIsValid(true);

    let err = checkMsgIsValid(rule.subject);
    if (err !== 'ok') {
      setFixErrorsMsg(`Subject: ${err}`);
      return;
    }
    err = checkMsgIsValid(rule.body);
    if (err !== 'ok') {
      setFixErrorsMsg(`Body: ${err}`);
      return;
    }

    if (e.currentTarget.checkValidity() === false) {
      setValidated(true);
      setFixErrorsMsg(fixErrorsMsg);
      e.stopPropagation();
    } else {
      if (origData.name === null) onAdd();
      else if (origData.name !== ruleName) onDeleteThenAdd();
      else if (origData.name === ruleName) onUpdate();
    }
  }

  function onDelete() {
    setShowModal(
      <Confirm
        question='Are you sure you want to delete this rule?'
        onConfirm={() => {
          deleteRule(courseId, origData.name || ruleName)
            .then(() => {
              toast.success('Successfully deleted rule!');
              reset();
              handleReturnToDashboard();
            })
            .catch(() => toast.error('Failed to delete rule!'));
        }}
      />,
    );
  }

  async function copyData() {
    try {
      await navigator.clipboard.writeText(JSON.stringify(rule, null, 2));
      toast.success('Successfully copied rule data to clipboard!');
    } catch (err) {
      toast.error('Something went wrong when copying: ' + err.name);
    }
  }

  async function pasteData() {
    try {
      let pastedData = await navigator.clipboard.readText();
      pastedData = JSON.parse(pastedData);

      const { modifiedObj, keysPresent, keysAbsent } = deepCompare(
        pastedData,
        rule,
      );
      modifiedObj.name = ruleName;
      setShowModal(
        <Confirm
          question={
            <div className='small'>
              <div>Are you sure? These values will be overwritten.</div>
              <div
                style={{ maxHeight: '160px' }}
                className='overflow-auto border rounded p-2 my-1'
              >
                {keysPresent.map((x, i) => {
                  if (x.includes('id') || x === 'name') return;
                  return <div key={i}>{title(x.replaceAll('.', ' > '))}</div>;
                })}
              </div>
              {keysAbsent.length > 0 && (
                <Alert variant='danger' className='p-2 mt-3 mb-0'>
                  <div>
                    These values will be ignored as they were absent from the
                    pasted data:
                  </div>
                  <div
                    style={{ maxHeight: '120px' }}
                    className='overflow-auto my-1'
                  >
                    {keysAbsent.map((x, i) => (
                      <div key={i}>{title(x.replaceAll('.', ' > '))}</div>
                    ))}
                  </div>
                </Alert>
              )}
            </div>
          }
          onConfirm={() => {
            setRule(modifiedObj);
            toast.success('Successfully populated rule configuration!');
          }}
        />,
      );
    } catch (err) {
      if (err instanceof SyntaxError)
        toast.error('Failed to paste - invalid data!');
      else toast.error(err.message);
    }
  }

  return (
    <div className='pb-3'>
      {rule && (
        <div className='border shadow rounded-2 bg-white'>
          <div className='d-flex flex-row fw-bold bg-primary p-2 rounded-top-2 text-light m-0'>
            <div className='flex-grow-1'>Rule Configuration</div>
            <i
              className='bi bi-layout-split ms-2 d-none d-xl-block pointer'
              onClick={() => setDisplay(6)}
            />
            <i
              className='bi bi-view-stacked ms-2 d-none d-xl-block pointer'
              onClick={() => setDisplay(12)}
            />
          </div>
          <Form
            onSubmit={onSave}
            noValidate
            validated={validated}
            className='p-3'
          >
            {students && assignments ? (
              <Row className='m-0'>
                <Col xs={12} xl={display} className='p-1'>
                  <RuleConfig
                    courseId={courseId}
                    rule={rule}
                    ruleName={ruleName}
                    setRuleName={setRuleName}
                    setRule={setRule}
                    allRules={rules}
                    students={students}
                    assignments={assignments}
                    validDays={validDays}
                    validStu={stuIsValid}
                    validAsg={asgIsValid}
                    invalidName={invalidName}
                  />
                </Col>
                <Col xs={12} xl={display} className='p-1'>
                  <MessageConfig rule={rule} setRule={setRule} />
                </Col>
              </Row>
            ) : (
              <>
                <Placeholder as='p' animation='glow'>
                  <Placeholder xs={3} />
                </Placeholder>
                <Placeholder as='p' animation='glow'>
                  <Placeholder xs={4} />
                </Placeholder>
              </>
            )}
            <div className='text-end border-top mt-3'>
              <span className='text-danger small'>{fixErrorsMsg}</span>
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip>Copy Rule Configuration to Clipboard</Tooltip>
                }
              >
                <Button
                  className='m-1'
                  variant='light'
                  size='md'
                  onClick={() => copyData()}
                >
                  <i className='bi bi-copy'></i>
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip>Paste Rule Configuration from Clipboard</Tooltip>
                }
              >
                <Button
                  className='m-1'
                  variant='light'
                  size='md'
                  onClick={() => pasteData()}
                >
                  <i className='bi bi-clipboard'></i>
                </Button>
              </OverlayTrigger>
              {JSON.stringify(origData) !==
                JSON.stringify({ ...rule, name: ruleName }) && (
                <Button
                  className='m-1 text-light'
                  variant='info'
                  size='md'
                  type={'submit'}
                >
                  <i className='bi bi-floppy-fill'></i> Save
                </Button>
              )}
              {origData.name !== null && (
                <Button
                  className='m-1 text-light'
                  variant='danger'
                  size='md'
                  onClick={onDelete}
                >
                  <i className='bi bi-trash-fill'></i> Delete
                </Button>
              )}
            </div>
          </Form>
        </div>
      )}
    </div>
  );
}

export default memo(RuleCard);
