import React, { memo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useGlobalVars } from '../../../App';
import { title } from '../../util/string_methods';

function formatDocTitle(title) {
  return `${title} - RamDesk`;
}

function Breadcrumbs({ loggedIn }) {
  const location = useLocation();
  const pathsToIgnore = [
    '',
    'courses',
    'login',
    'logout',
    'oauth2',
    'response',
  ];
  const pathsNoLink = ['Code', 'Text'];
  const { activeCourse, activeAssignment } = useGlobalVars();

  return (
    <div className='flex-grow-1 d-flex align-items-center border-start border-2 h-100 p-2 text-light fw-semibold overflow-auto hide-scrollbar'>
      <NavLink
        className='breadcrumb-link'
        to={loggedIn ? '/courses' : '/login'}
      >
        RamDesk
      </NavLink>
      {location.pathname.split('/').map((path, index, array) => {
        if (pathsToIgnore.includes(path)) {
          document.title = formatDocTitle(title(path));
          return null;
        }

        if (activeCourse && path == activeCourse.id) path = activeCourse.code;
        else if (activeAssignment && path == activeAssignment.id)
          path = activeAssignment.name;
        else path = title(path);
        document.title = formatDocTitle(path);

        return (
          <React.Fragment key={index}>
            <i className='bi bi-chevron-right mx-1 small'></i>
            {index === array.length - 1 || pathsNoLink.includes(path) ? (
              <span
                className='fw-normal truncateTxt'
                style={{
                  maxWidth: '20ch',
                  minWidth: `${Math.min(path.length, 12)}ch`,
                }}
              >
                {path}
              </span>
            ) : (
              <NavLink
                className={'truncateTxt breadcrumb-link text-center'}
                style={{
                  maxWidth: '20ch',
                  minWidth: `${Math.min(path.length, 12)}ch`,
                }}
                to={array.slice(0, index + 1).join('/')}
              >
                {path}
              </NavLink>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default memo(Breadcrumbs);
