import { Alert, ListGroup } from 'react-bootstrap';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import * as ds from '../../util/descriptive_statistics';

export const COLORS = ['#008fb3', '#e56a54', '#ffc038', '#82c503'];

export function addMissingDate(sortedData) {
  let allDates = [];

  const dayMs = 24 * 60 * 60 * 1000;
  for (let i = 0; i < sortedData.length - 1; i++) {
    const start = new Date(sortedData[i].Date);
    const end = new Date(sortedData[i + 1].Date);
    const dayDiff = Math.floor(Math.abs(end - start) / dayMs);

    allDates.push(sortedData[i]);
    for (let j = 1; j < dayDiff; j++) {
      let newDate = new Date(start);
      newDate.setDate(newDate.getDate() + j);
      allDates.push({
        Date: newDate.toLocaleString('en-us', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
        Count: 0,
      });
    }
  }
  allDates.push(sortedData[sortedData.length - 1]);

  return allDates;
}

export function customLabel(props) {
  const { x, y, index, height, width, key, data } = props;
  const labelName = data[index][`name${key}`];
  const labelValue = data[index][`count${key}`];
  if (labelName === undefined || labelValue === 0) return;
  let textContent = `${labelName} (${labelValue})`;

  const maxChars = Math.floor(width / 6);
  if (textContent.length > maxChars) {
    textContent = textContent.slice(0, maxChars - 3) + '...';
  }

  return (
    <g>
      <text
        x={x + width / 2}
        y={y + height / 2}
        textAnchor='middle'
        dominantBaseline={'middle'}
        fontWeight={'bold'}
        fill='white'
        fontSize={'12'}
      >
        {textContent}
      </text>
    </g>
  );
}

export function DisplayOnError({ msg }) {
  return (
    <div className='h-100 d-flex align-items-center justify-content-center'>
      <Alert variant='danger' className='px-5'>
        There was an error when generating this plot... {msg}
      </Alert>
    </div>
  );
}

export function DisplayOnEmpty() {
  return (
    <div className='h-100 d-flex align-items-center justify-content-center'>
      <Alert variant='danger' className='px-5'>
        No plots to show as there is no data...
      </Alert>
    </div>
  );
}

export function DescriptiveStatistics({ sortedData }) {
  const descStats = {
    mean: ds.calculateMean(sortedData).toFixed(2),
    median: ds.calculateMedian(sortedData).toFixed(2),
    min: ds.calculateMinimum(sortedData).toFixed(2),
    max: ds.calculateMaximum(sortedData).toFixed(2),
    q1: ds.calculateQuartile(sortedData, 0.25).toFixed(2),
    q3: ds.calculateQuartile(sortedData, 0.75).toFixed(2),
    std: ds.calculateStd(sortedData).toFixed(2),
    count: sortedData.length,
  };

  return (
    <div className='mt-2 d-flex flex-row flex-wrap justify-content-center'>
      <ListGroup horizontal className='p-1 small'>
        <ListGroup.Item
          variant='light'
          className='p-1'
        >{`Min: ${descStats.min}`}</ListGroup.Item>
        <ListGroup.Item
          variant='light'
          className='p-1'
        >{`Q1: ${descStats.q1}`}</ListGroup.Item>
        <ListGroup.Item
          variant='light'
          className='p-1'
        >{`Median: ${descStats.median}`}</ListGroup.Item>
        <ListGroup.Item
          variant='light'
          className='p-1'
        >{`Q3: ${descStats.q3}`}</ListGroup.Item>
        <ListGroup.Item
          variant='light'
          className='p-1'
        >{`Max: ${descStats.max}`}</ListGroup.Item>
      </ListGroup>
      <ListGroup horizontal className='p-1 small'>
        <ListGroup.Item
          variant='light'
          className='p-1'
        >{`Mean: ${descStats.mean}`}</ListGroup.Item>
        <ListGroup.Item
          variant='light'
          className='p-1'
        >{`Std: ${descStats.std}`}</ListGroup.Item>
        <ListGroup.Item
          variant='light'
          className='p-1'
        >{`Count: ${descStats.count}`}</ListGroup.Item>
      </ListGroup>
    </div>
  );
}

export function CoursesPerSemester({ data }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  try {
    let set = new Set();
    const groupedBySem = data.reduce((acc, i) => {
      const key = i.semester;
      if (!set.has(i.course_id)) acc[key] = (acc[key] || 0) + 1;
      set.add(i.course_id);
      return acc;
    }, {});
    const dataToPlot = Object.entries(groupedBySem).map(([k, v]) => {
      return { Semester: k, Count: v };
    });
    return (
      <ResponsiveContainer>
        <BarChart data={dataToPlot} margin={{ bottom: 15 }}>
          <CartesianGrid />
          <XAxis
            dataKey={'Semester'}
            label={{ value: 'SEMESTER', position: 'insideBottom', offset: -10 }}
          />
          <YAxis
            label={{ value: 'Num Courses', angle: -90, dx: -15 }}
            type='number'
          />
          <Tooltip />
          <Bar dataKey={'Count'} fill={COLORS[0]} maxBarSize={100} />
        </BarChart>
      </ResponsiveContainer>
    );
  } catch {
    return <DisplayOnError msg={'Could be because the date is invalid.'} />;
  }
}

export function AssignmentsPerPeriod({ data, by }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  try {
    const groupedBy = data.reduce((acc, i) => {
      const key = i[by];
      const aId = i.assignment_id;
      acc[key] = acc[key] || [];
      acc[key].push(aId);
      return acc;
    }, {});
    const dataToPlot = Object.entries(groupedBy).map(([k, v]) => {
      return { Period: k, Count: new Set(v).size };
    });
    return (
      <ResponsiveContainer>
        <BarChart data={dataToPlot} margin={{ bottom: 15 }}>
          <CartesianGrid />
          <XAxis
            dataKey={'Period'}
            label={{
              value: by.toUpperCase(),
              position: 'insideBottom',
              offset: -10,
            }}
          />
          <YAxis
            label={{ value: 'Num Students', angle: -90, dx: -15 }}
            type='number'
          />
          <Tooltip />
          <Bar dataKey={'Count'} fill={COLORS[0]} maxBarSize={100} />
        </BarChart>
      </ResponsiveContainer>
    );
  } catch (e) {
    console.log(e);
    return <DisplayOnError />;
  }
}

export function PlotByHour({ data, _key }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  try {
    const hourCounts = data.reduce((acc, i) => {
      const hour = new Date(i[_key]).getHours();
      acc[hour] = (acc[hour] || 0) + 1;
      return acc;
    }, {});
    const dataToPlot = Object.entries(hourCounts).map(([k, v]) => {
      return { Hour: Number(k), Count: v };
    });
    return (
      <ResponsiveContainer>
        <BarChart data={dataToPlot} margin={{ bottom: 15 }}>
          <CartesianGrid />
          <XAxis
            dataKey={'Hour'}
            label={{ value: 'Hour', position: 'insideBottom', offset: -10 }}
            type='number'
            ticks={[
              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
              19, 20, 21, 22, 23,
            ]}
            padding={{ left: 20, right: 20 }}
          />
          <YAxis
            label={{ value: 'Count', angle: -90, dx: -15 }}
            type='number'
          />
          <Tooltip />
          <Bar dataKey={'Count'} fill={COLORS[0]} maxBarSize={100} />
        </BarChart>
      </ResponsiveContainer>
    );
  } catch {
    return <DisplayOnError msg={'Could be because the date is invalid.'} />;
  }
}

export function PlotByDay({ data, _key }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  try {
    const dayOrder = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const dayCounts = data.reduce((acc, i) => {
      const day = new Date(i[_key]).toLocaleString('en-us', {
        weekday: 'long',
      });
      acc[day] = (acc[day] || 0) + 1;
      return acc;
    }, {});
    dayOrder.forEach((x) => {
      if (dayCounts[x] === undefined) dayCounts[x] = 0;
    });
    const dataToPlot = Object.entries(dayCounts).map(([k, v]) => {
      return { Day: k, Count: v };
    });
    const sortedData = dataToPlot.sort((a, b) => {
      return dayOrder.indexOf(a.Day) - dayOrder.indexOf(b.Day);
    });
    return (
      <ResponsiveContainer>
        <BarChart data={sortedData} margin={{ bottom: 15 }}>
          <CartesianGrid />
          <XAxis
            dataKey={'Day'}
            label={{ value: 'Day', position: 'insideBottom', offset: -10 }}
          />
          <YAxis
            label={{ value: 'Count', angle: -90, dx: -15 }}
            type='number'
          />
          <Tooltip />
          <Bar dataKey={'Count'} fill={COLORS[0]} maxBarSize={100} />
        </BarChart>
      </ResponsiveContainer>
    );
  } catch {
    return <DisplayOnError msg={'Could be because the date is invalid.'} />;
  }
}

export function PlotByDate({ data, _key }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  try {
    const dayCounts = data.reduce((acc, i) => {
      const date = new Date(i[_key]).toLocaleString('en-us', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});
    const dataToPlot = Object.entries(dayCounts).map(([k, v]) => {
      return { Date: k, Count: v };
    });
    const sortedData = dataToPlot.sort((a, b) => {
      return a.Date.localeCompare(b.Date);
    });
    const fullData = addMissingDate(sortedData);
    return (
      <ResponsiveContainer>
        <BarChart data={fullData} margin={{ bottom: 70, right: 40 }}>
          <CartesianGrid />
          <XAxis
            dataKey={'Date'}
            label={{ value: 'Date', position: 'insideBottom', offset: -60 }}
            tickCount={sortedData.length}
            textAnchor='start'
            angle={45}
          />
          <YAxis
            label={{ value: 'Count', angle: -90, dx: -15 }}
            type='number'
          />
          <Tooltip />
          <Bar dataKey={'Count'} fill={COLORS[0]} maxBarSize={100} />
        </BarChart>
      </ResponsiveContainer>
    );
  } catch {
    return <DisplayOnError msg={'Could be because the date is invalid.'} />;
  }
}
