import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  COLORS,
  DescriptiveStatistics,
  DisplayOnEmpty,
  DisplayOnError,
} from './Common';

export function ExtraDaysDistribution({ data }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  try {
    const allDays = data.map((x) => x.days).sort((a, b) => a - b);
    const daysCount = allDays.reduce((acc, i) => {
      acc[i] = (acc[i] || 0) + 1;
      return acc;
    }, {});
    const dataToPlot = Object.entries(daysCount).map(([k, v]) => {
      return { Days: k, Students: v };
    });

    return (
      <ResponsiveContainer>
        <BarChart data={dataToPlot}>
          <CartesianGrid />
          <XAxis
            dataKey={'Days'}
            label={{ value: 'Days', position: 'insideBottom', offset: -5 }}
            type='number'
            interval={0}
            padding={{ left: 80, right: 80 }}
          />
          <YAxis
            label={{ value: 'Students', angle: -90, dx: -15 }}
            type='number'
          />
          <Tooltip />
          <Bar dataKey={'Students'} fill={COLORS[0]} maxBarSize={100} />
          <Legend
            content={<DescriptiveStatistics sortedData={allDays} />}
            verticalAlign='bottom'
          />
        </BarChart>
      </ResponsiveContainer>
    );
  } catch {
    return <DisplayOnError />;
  }
}

export function ExtraTimeDistribution({ data }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  try {
    const allTimes = data.map((x) => x.time).sort((a, b) => a - b);
    const daysCount = allTimes.reduce((acc, i) => {
      acc[i] = (acc[i] || 0) + 1;
      return acc;
    }, {});
    const dataToPlot = Object.entries(daysCount).map(([k, v]) => {
      return { Time: k, Students: v };
    });

    return (
      <ResponsiveContainer>
        <BarChart data={dataToPlot}>
          <CartesianGrid />
          <XAxis
            dataKey={'Time'}
            label={{ value: 'Time', position: 'insideBottom', offset: -5 }}
            type='number'
            interval={0}
            padding={{ left: 80, right: 80 }}
          />
          <YAxis
            label={{ value: 'Students', angle: -90, dx: -15 }}
            type='number'
          />
          <Tooltip />
          <Bar dataKey={'Students'} fill={COLORS[0]} maxBarSize={100} />
          <Legend
            content={<DescriptiveStatistics sortedData={allTimes} />}
            verticalAlign='bottom'
          />
        </BarChart>
      </ResponsiveContainer>
    );
  } catch {
    return <DisplayOnError />;
  }
}

export function ExtensionsPerPeriod({ data, by }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  try {
    const groupedBy = data.reduce((acc, i) => {
      const key = i[by];
      if (i.days > 0) acc[key] = (acc[key] || 0) + i.n_assignments;
      if (i.time > 1) acc[key] = (acc[key] || 0) + i.n_quizzes;
      return acc;
    }, {});
    const dataToPlot = Object.entries(groupedBy).map(([k, v]) => {
      return { Period: k, Extensions: v };
    });
    return (
      <ResponsiveContainer>
        <BarChart data={dataToPlot} margin={{ bottom: 15 }}>
          <CartesianGrid />
          <XAxis
            dataKey={'Period'}
            label={{
              value: by.toUpperCase(),
              position: 'insideBottom',
              offset: -10,
            }}
          />
          <YAxis
            label={{ value: 'Num Extensions', angle: -90, dx: -20 }}
            type='number'
          />
          <Tooltip />
          <Bar dataKey={'Extensions'} fill={COLORS[0]} maxBarSize={100} />
        </BarChart>
      </ResponsiveContainer>
    );
  } catch {
    return <DisplayOnError />;
  }
}

export function ExtensionTypePerPeriod({ data, by }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  try {
    const asgPerPeriod = data.reduce((acc, i) => {
      const key = i[by];
      const hasExt = i.days > 0 ? 1 : 0;
      acc[key] = (acc[key] || 0) + hasExt;
      return acc;
    }, {});
    const quizPerPeriod = data.reduce((acc, i) => {
      const key = i[by];
      const hasExt = i.time > 1 ? 1 : 0;
      acc[key] = (acc[key] || 0) + hasExt;
      return acc;
    }, {});
    const dataToPlot = Object.entries(asgPerPeriod).map(([k, v]) => {
      return {
        Period: k,
        'Day Extensions': v,
        'Quiz Extensions': quizPerPeriod[k],
      };
    });
    return (
      <ResponsiveContainer>
        <BarChart data={dataToPlot} margin={{ bottom: 15 }}>
          <CartesianGrid />
          <XAxis
            dataKey={'Period'}
            label={{
              value: by.toUpperCase(),
              position: 'insideBottom',
              offset: -10,
            }}
          />
          <YAxis
            label={{ value: 'Num Students', angle: -90, dx: -15 }}
            type='number'
          />
          <Tooltip />
          <Bar dataKey={'Day Extensions'} fill={COLORS[0]} maxBarSize={100} />
          <Bar dataKey={'Quiz Extensions'} fill={COLORS[1]} maxBarSize={100} />
        </BarChart>
      </ResponsiveContainer>
    );
  } catch {
    return <DisplayOnError />;
  }
}

export function QuizzesVsAssignments({ data, by }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  try {
    let set = new Set();
    const asgPerPeriod = data.reduce((acc, i) => {
      const key = i[by];
      if (!set.has(i.course_id)) acc[key] = (acc[key] || 0) + i.n_assignments;
      set.add(i.course_id);
      return acc;
    }, {});
    set.clear();
    const quizPerPeriod = data.reduce((acc, i) => {
      const key = i[by];
      if (!set.has(i.course_id)) acc[key] = (acc[key] || 0) + i.n_quizzes;
      set.add(i.course_id);
      return acc;
    }, {});
    const dataToPlot = Object.entries(asgPerPeriod).map(([k, v]) => {
      return { Period: k, Assignments: v, Quizzes: quizPerPeriod[k] };
    });
    return (
      <ResponsiveContainer>
        <BarChart data={dataToPlot} margin={{ bottom: 15 }}>
          <CartesianGrid />
          <XAxis
            dataKey={'Period'}
            label={{
              value: by.toUpperCase(),
              position: 'insideBottom',
              offset: -10,
            }}
          />
          <YAxis
            label={{ value: 'Count', angle: -90, dx: -15 }}
            type='number'
          />
          <Tooltip />
          <Bar dataKey={'Assignments'} fill={COLORS[0]} maxBarSize={100} />
          <Bar dataKey={'Quizzes'} fill={COLORS[1]} maxBarSize={100} />
        </BarChart>
      </ResponsiveContainer>
    );
  } catch {
    return <DisplayOnError />;
  }
}

export function StudentsPerPeriod({ data, by }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  try {
    const groupedBy = data.reduce((acc, i) => {
      const key = i[by];
      acc[key] = [...(acc[key] || []), i.student_id];
      return acc;
    }, {});
    const dataToPlot = Object.entries(groupedBy).map(([k, v]) => {
      return { Period: k, Count: v.length };
    });
    return (
      <ResponsiveContainer>
        <BarChart data={dataToPlot} margin={{ bottom: 15 }}>
          <CartesianGrid />
          <XAxis
            dataKey={'Period'}
            label={{
              value: by.toUpperCase(),
              position: 'insideBottom',
              offset: -10,
            }}
          />
          <YAxis
            label={{ value: 'Num Students', angle: -90, dx: -15 }}
            type='number'
          />
          <Tooltip />
          <Bar dataKey={'Count'} fill={COLORS[0]} maxBarSize={100} />
        </BarChart>
      </ResponsiveContainer>
    );
  } catch {
    return <DisplayOnError />;
  }
}
