import { memo, useEffect, useRef, useState } from 'react';
import { Col, InputGroup, ListGroup, Row, Tab, Tabs } from 'react-bootstrap';
import { getInspectStats } from '../../api/inspect_api.js';
import * as ip from '../plots/InspectPlots.js';
import PlotsContainer from '../plots/PlotsContainer.js';
import * as wrapper from '../plots/Wrapper.js';
import SearchSelect from '../reusable/SearchSelect.js';

function Statistics({ courseId, assignments }) {
  const [data, setData] = useState(null);
  const [currData, setCurrData] = useState([]);
  const [asgOptions, setAsgOptions] = useState(null);
  const [selectedTool, setSelectedTool] = useState('code');
  const currAssignment = useRef(wrapper.defaultCurrVal);

  function getAssignmentName(id) {
    const result = assignments.find((item) => item.id === id);
    return result.name || null;
  }

  useEffect(() => {
    getInspectStats(courseId).then((res) => {
      const updatedRes = res.map((x) => ({
        ...x,
        assignment_name: getAssignmentName(x.assignment_id),
      }));
      setData(updatedRes);
      setCurrData(updatedRes);
      setAsgOptions(
        wrapper.getUniqueItemsAsObj(
          'assignment_id',
          'assignment_name',
          updatedRes,
        ),
      );
    });
  }, [courseId]);

  function filterByAssignment(val) {
    let filteredData = data;
    if (val.id !== null) {
      filteredData = data.filter((x) => x['assignment_id'] == val.id);
    }
    setCurrData(filteredData);
    currAssignment.current = val;
  }

  function FilterDashboardHelper() {
    return (
      <Col>
        <InputGroup size='sm' className='mb-1 flex-nowrap'>
          <InputGroup.Text style={{ width: '100px' }}>
            Assignment
          </InputGroup.Text>
          <SearchSelect
            idNameRef={currAssignment}
            items={asgOptions}
            onSelect={filterByAssignment}
            setToNull={false}
            displayId={true}
            placeholder={'ALL'}
          />
        </InputGroup>
      </Col>
    );
  }

  function getTotalStats(data) {
    return [
      <wrapper.TotalStats
        key='2'
        title={'Total Assignments'}
        subtitle={'that have used Inspect'}
        stat={wrapper.getUniqueItems(data, 'assignment_id').length}
      />,
      <wrapper.TotalStats
        key='3'
        title={'Total Code Inspections'}
        subtitle={'impacted by Inspect'}
        stat={data.reduce(
          (total, x) => total + (x.target === 'code' ? 1 : 0),
          0,
        )}
      />,
      <wrapper.TotalStats
        key='4'
        title={'Total Text Inspections'}
        subtitle={'Inspectd by Inspect'}
        stat={data.reduce(
          (total, x) => total + (x.target === 'text' ? 1 : 0),
          0,
        )}
      />,
    ];
  }

  function GeneralStatisticsHelper() {
    const stats = getTotalStats(data);
    return <ListGroup variant='flush'>{stats.map((x) => x)}</ListGroup>;
  }

  function getHeader() {
    if (currAssignment.current.id !== null)
      return `Stats for ${currAssignment.current.name}`;
    return `Filtered Stats`;
  }

  function getBody() {
    let arr = getTotalStats(currData).slice(1);
    return <>{arr.map((x) => x)}</>;
  }

  function plotsData() {
    const getResults = () => {
      let results = [];
      for (const cd of currData) {
        if (Array.isArray(cd.results) && cd.target === selectedTool)
          results.push(...(cd.results || []));
      }
      return results;
    };

    return [
      {
        title: 'Code Match Distribution',
        element: <ip.MatchDistribution data={getResults()} type={'code'} />,
        display: selectedTool === 'code',
      },
      {
        title: 'Text Match Distribution',
        element: <ip.MatchDistribution data={getResults()} type={'text'} />,
        display: selectedTool === 'text',
      },
      {
        title: 'Average Code Match % for Students',
        element: <ip.AverageMatch data={getResults()} type={'code'} />,
        display: selectedTool === 'code',
      },
      {
        title: 'Average Text Match % for Students',
        element: <ip.AverageMatch data={getResults()} type={'text'} />,
        display: selectedTool === 'text',
      },
    ];
  }

  return (
    <div className='d-flex flex-column h-100'>
      <Row className='m-0'>
        <Col xs={6} className='d-md-block d-none py-1 px-0' />
        <Col xs={12} md={6} className='px-2 inspect-toggle'>
          <Tabs
            fill
            activeKey={selectedTool}
            onSelect={(e) => setSelectedTool(e)}
          >
            <Tab
              eventKey={'code'}
              title={
                <>
                  <i
                    className={`bi bi-file-earmark-code${selectedTool === 'code' ? '-fill' : ''}`}
                  />
                  {' Code'}
                </>
              }
            />
            <Tab
              eventKey={'text'}
              title={
                <>
                  <i
                    className={`bi bi-file-earmark-font${selectedTool === 'text' ? '-fill' : ''}`}
                  />
                  {' Text'}
                </>
              }
            />
          </Tabs>
        </Col>
      </Row>
      <wrapper.Wrapper ifNull={data === null}>
        <wrapper.FilterDashboard child={<FilterDashboardHelper />} />
        <wrapper.GeneralStatistics child={<GeneralStatisticsHelper />} />
        <wrapper.FilteredStatistics
          showDef={currAssignment.current.name}
          getHead={getHeader}
          getBody={getBody}
        />
        <PlotsContainer data={plotsData()} />
      </wrapper.Wrapper>
    </div>
  );
}

export default memo(Statistics);
