import he from 'he';
import { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Collapse,
  Form,
  ListGroup,
  Modal,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import ReactDOMServer from 'react-dom/server';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalVars } from '../../../App';
import { title } from '../../util/string_methods';
import { REGISTRY } from './Registry';

export async function copy(text) {
  const blob = new Blob([text], { type: 'text/plain' });
  const clipboardItem = new ClipboardItem({ 'text/plain': blob });
  await navigator.clipboard.write([clipboardItem]);
}

function getDisplayName(id) {
  return title(id.replaceAll('_', ' '));
}

function NoSearchResults({ text }) {
  return <div>{`There were no matches for "${text}"...`}</div>;
}

function getSearchResults(by, data, displayComponent) {
  let matched = [];
  let reToMatch = by
    .split(' ')
    .map((x) => `(${x})`)
    .join('(.{0,30}?)');
  reToMatch = new RegExp(reToMatch, 'i');

  for (const x of data) {
    const matchedArr = x.text.match(reToMatch);
    if (matchedArr !== null) {
      matched.push({
        key: x.key,
        path: x.path,
        component: x.component,
        groups: matchedArr,
      });
    }
  }

  return (
    <>
      <div className='fw-bold p-1'>{`Search Results (${matched.length})`}</div>
      {matched.length === 0 ? (
        <NoSearchResults text={by} />
      ) : (
        <ListGroup>
          {matched.map((x, i) => {
            const startIdx = Math.max(x.groups.index - 300, 0);
            const endIdx = Math.min(
              x.groups.index + x.groups[0].length + 300,
              x.groups.input.length,
            );

            let textToDisplay = startIdx === 0 ? '' : '...';
            textToDisplay += x.groups.input.slice(startIdx, endIdx);
            textToDisplay += endIdx === x.groups.input.length ? '' : '...';
            x.groups.index =
              startIdx === 0 ? x.groups.index : x.groups.index - startIdx + 3;

            return (
              <ListGroup.Item
                key={i}
                action
                className='py-1 mb-2 small'
                onClick={() => displayComponent(x, true)}
              >
                <div className='fw-bold truncateTxt text-primary mb-2'>
                  {x.path.replaceAll('/', ' > ').toUpperCase()}
                </div>
                <span>{textToDisplay.slice(0, x.groups.index)}</span>
                {x.groups.map((y, i) => {
                  if (i === 0) return;
                  return (
                    <span
                      className={
                        by.toLowerCase().includes(y.toLowerCase())
                          ? 'bg-warning'
                          : ''
                      }
                      key={i}
                    >
                      {y}
                    </span>
                  );
                })}
                <span>
                  {textToDisplay.slice(x.groups.index + x.groups[0].length)}
                </span>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      )}
    </>
  );
}

function getRegistryText() {
  let texts = [];

  (function recurse(data) {
    for (const x of data) {
      const innerText = ReactDOMServer.renderToString(x.component)
        .replace(/<[^>]*>/g, ' ')
        .replace(/\s{2,}/g, ' ')
        .replace(/^\s/, '');
      texts.push({
        ...x,
        text: he.decode(innerText),
      });
      if (x.children !== null) recurse(x.children);
    }
  })(REGISTRY);

  return texts;
}

function Docs({ initial_at = 'home', fullscreen = false }) {
  const [at, setAt] = useState(initial_at);
  useEffect(() => {
    setAt(initial_at);
  }, [initial_at]);
  const [fullKey, setFullKey] = useState([]);
  const { setShowModal } = useGlobalVars();
  const [activeId, setActiveId] = useState(at);
  const [path, setPath] = useState(null);
  const [activeComponent, setActiveComponent] = useState(null);
  const [sidebarHidden, setSidebarHidden] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const REGISTRY_TEXT = getRegistryText();

  useEffect(() => {
    if (searchTerm === '') return;
    const searchResult = getSearchResults(
      searchTerm,
      REGISTRY_TEXT,
      displayComponent,
    );

    if (fullscreen && !window.location.pathname.includes('search'))
      window.history.pushState({}, null, `/docs/search`);
    setActiveComponent(searchResult);
    setPath('search');
    setActiveId('search');
  }, [searchTerm]);

  useEffect(() => {
    if (at === 'search') {
      setSearchTerm('');
      setActiveComponent(<div className='fw-bold p-1'>Search Results</div>);
      return;
    }

    let fk = at.split('.');
    setFullKey(fk);
    const displayOnError = {
      component: <div className='small'>Page Not Found...</div>,
    };
    let body = REGISTRY;

    for (let i = 0; i < fk.length; i++) {
      if (body === undefined) {
        body = displayOnError;
        break;
      }
      const result = body.find((x) => x.key === fk[i]);
      if (result === undefined) {
        body = displayOnError;
        break;
      }
      if (result.children === null || fk.length === i + 1) body = result;
      else body = result.children;
    }

    setActiveId(fk[fk.length - 1]);
    setPath(body.path);
    setActiveComponent(body.component);
  }, [at]);

  function shouldCollapseChildren(key) {
    return !fullKey.slice(0, -1).includes(key);
  }

  function displayComponent(x, expand_section = false) {
    if (fullscreen) navigate(`/docs/${x.path}`);
    if (expand_section) {
      const new_at = x.path.replace(/\//g, '.');
      setAt(new_at);
    } else {
      setActiveComponent(x.component);
      setPath(x.path);
      setActiveId(x.key);
    }
    setSearchTerm('');
  }

  function addChildren(items) {
    return items.map((x) => {
      const collapsed = shouldCollapseChildren(x.key);
      const [childCollapsed, setChildCollapsed] = useState(true);
      useEffect(() => {
        setChildCollapsed(collapsed);
      }, [collapsed]);

      return x.children === null ? (
        <Button
          key={x.key}
          className='d-block text-start w-100 truncateTxt border-0 rounded-0 mb-1 docsButton'
          active={activeId === x.key && path === x.path}
          onClick={() => displayComponent(x)}
          variant=''
        >
          {getDisplayName(x.key)}
        </Button>
      ) : (
        <div key={x.key}>
          <ButtonGroup className='w-100'>
            <Button
              className='text-start w-75 border-0 rounded-0 mb-1 docsButton'
              active={activeId === x.key}
              onClick={() => displayComponent(x)}
              variant=''
            >
              {getDisplayName(x.key)}
            </Button>
            <Button
              className='p-0 w-25 border-0 rounded-0 mb-1 docsButton'
              variant=''
              onClick={() => setChildCollapsed(!childCollapsed)}
            >
              {childCollapsed ? (
                <i className='bi bi-chevron-down' />
              ) : (
                <i className='bi bi-chevron-up' />
              )}
            </Button>
          </ButtonGroup>
          <Collapse in={!childCollapsed}>
            <div className='ms-3 border-start'>{addChildren(x.children)}</div>
          </Collapse>
        </div>
      );
    });
  }

  function modalBody() {
    return (
      <div
        className='d-flex flex-row'
        style={{ height: fullscreen ? '100%' : '70vh' }}
      >
        <div
          className='p-1 w-25 hide-scrollbar bg-white border-end'
          style={{ overflowX: 'hidden', minWidth: '200px' }}
          hidden={sidebarHidden}
        >
          <div className='d-flex flex-wrap align-items-center py-1'>
            <div className='flex-grow-1 d-flex align-items-center bg-white border rounded'>
              <i className='bi bi-search px-2' />
              <Form.Control
                size='sm'
                className='border-0'
                placeholder='Search'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          {addChildren(REGISTRY)}
        </div>
        <div style={{ position: 'relative' }}>
          <div
            className='nav-collapse-button'
            onClick={() => setSidebarHidden(!sidebarHidden)}
          >
            <i
              className={`bi bi-chevron-double-${sidebarHidden ? 'right' : 'left'} pointer`}
            />
          </div>
        </div>
        {activeComponent && (
          <div className='w-100 bg-white p-4 overflow-auto'>
            {activeComponent}
          </div>
        )}
      </div>
    );
  }

  return fullscreen ? (
    modalBody()
  ) : (
    <Modal
      fullscreen={fullscreen}
      show
      centered
      size='lg'
      scrollable
      onHide={() => setShowModal(null)}
    >
      <Modal.Header className='d-flex align-items-center bg-primary text-light p-2 fw-bold'>
        <div className='flex-grow-1'>RamDesk Docs</div>
        <OverlayTrigger
          placement='bottom'
          overlay={<Tooltip>Open in Another Tab</Tooltip>}
        >
          <a
            href={`/docs/${path}`}
            target='_blank'
            rel='noopener noreferrer'
            className='text-black'
          >
            <i className='bi bi-box-arrow-up-right text-light me-3 pointer' />
          </a>
        </OverlayTrigger>
        <i className='bi bi-x-lg pointer' onClick={() => setShowModal(null)} />
      </Modal.Header>
      {modalBody()}
    </Modal>
  );
}

export function DocsHelp({ at, icon = 'question-circle-fill' }) {
  const { setShowModal } = useGlobalVars();
  return (
    <i
      className={`bi bi-${icon} mx-2 text-info pointer`}
      onClick={() => setShowModal(<Docs initial_at={at} />)}
    ></i>
  );
}

export function DocsFull() {
  const location = useLocation();
  let at = location.pathname.replace('/docs/', '').replaceAll('/', '.');
  if (at === '' || at.includes('docs')) at = 'home';

  return <Docs initial_at={at} fullscreen />;
}

export function CourseDocsButton({ at }) {
  const { setShowModal } = useGlobalVars();
  return (
    <ListGroup.Item
      action
      className='d-flex align-items-center px-2'
      onClick={() => setShowModal(<Docs initial_at={at} />)}
    >
      <i className='bi bi-question-circle-fill fs-5' />
      <div className='truncateTxt ms-2'>About</div>
    </ListGroup.Item>
  );
}
