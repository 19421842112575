import { memo, useEffect, useState } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useGlobalVars } from '../../../App';
import getAssignmentsForCourse from '../../api/assignments_api';
import getStudentsForCourse from '../../api/students_api';
import { CourseDocsButton } from '../docs/Docs';
import ExtendRouting from '../extend/ExtendRouting';
import Grade from '../grade/Grade';
import Inspect from '../inspect/Inspect';
import Nudge from '../nudge/Nudge';
import Error from '../reusable/Error';

export const TOOLS = [
  {
    name: 'Extend',
    description:
      'Give extensions for all assignments and quizzes to students with a single click.',
    icon: 'bi-clock-fill',
    link: 'extend',
  },
  {
    name: 'Nudge',
    description:
      'Send personalized nudges to students based on assignment deadlines/grades in the course.',
    icon: 'bi-hand-index-fill',
    link: 'nudge',
  },
  {
    name: 'Grade',
    description:
      'Configure grading scripts for automatic grading of programming assignments.',
    icon: 'bi-robot',
    link: 'grade',
  },
  {
    name: 'Inspect',
    description:
      'Generate reports on plagiarism and use of AI for programming and written assignments.',
    icon: 'bi-file-earmark-check-fill',
    link: 'inspect',
  },
];

function Course({ courseInfo }) {
  const [students, setStudents] = useState(null);
  const [assignments, setAssignments] = useState(null);
  const { setActiveCourse } = useGlobalVars();

  useEffect(() => {
    setActiveCourse(courseInfo);
    getStudentsForCourse(courseInfo.id).then(setStudents);
    getAssignmentsForCourse(courseInfo.id).then(setAssignments);
  }, [courseInfo]);

  return (
    <div className='h-100 p-3'>
      <Routes>
        <Route path='' element={<CourseView />} />
        <Route
          path='extend/*'
          element={
            <ExtendRouting
              courseId={courseInfo.id}
              students={students}
              assignments={assignments}
            />
          }
        />
        <Route
          path='nudge/*'
          element={
            <Nudge
              courseId={courseInfo.id}
              students={students}
              assignments={assignments}
            />
          }
        />
        <Route
          path='grade/*'
          element={
            <Grade
              courseId={courseInfo.id}
              students={students}
              assignments={assignments}
              courseCode={courseInfo.code}
            />
          }
        />
        <Route
          path='inspect/*'
          element={
            <Inspect courseId={courseInfo.id} assignments={assignments} />
          }
        />
        <Route
          path='/*'
          element={<Error error={404} message={'Page was not found!'} />}
        />
      </Routes>
    </div>
  );
}

function CourseView() {
  const navigate = useNavigate();

  return (
    <Row
      xs={1}
      sm={2}
      md={3}
      lg={4}
      xl={6}
      className='m-0 h-100 g-4 overflow-auto'
    >
      {TOOLS.map((tool, i) => (
        <Col key={i}>
          <Card className='card-animation display-card'>
            <Card.Body
              className='pointer'
              onClick={() => navigate(`${tool.link}`)}
            >
              <div className='d-flex align-items-center text-primary'>
                <i className={`bi ${tool.icon} fs-2 me-2`} />
                <div className='fw-bold fs-3'>{tool.name}</div>
              </div>
              <hr />
              <Card.Text
                className='text-muted overflow-hidden'
                style={{ height: '74px' }}
              >
                {tool.description}
              </Card.Text>
              <ListGroup onClick={(e) => e.stopPropagation()}>
                <CourseDocsButton at={tool.link} />
                <ListGroup.Item
                  action
                  className='d-flex align-items-center px-2'
                  onClick={() => navigate(`${tool.link}/statistics`)}
                >
                  <i className='bi bi-graph-up fs-5' />
                  <div className='truncateTxt ms-2'>Statistics</div>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
}

export default memo(Course);
