export const DEFAULT_CONFIG = {
  _id: {},
  active: false,
  schedule: {
    type: 'delay',
    delay_minutes: 0,
    hour: 9,
    days: [false, true, false, false, false, false, false],
  },
  networking: false,
  keep_highest: false,
  user_id: null,
  versions: {
    java: '21',
    junit: null,
    python: '3.13',
  },
};

export const DEFAULT_TASKS = {
  extract: {
    enabled: false,
    grade: '0',
  },
  check_files: {
    enabled: false,
    grade: '0',
    required: [],
    allowed: [],
  },
  check_code: {
    enabled: false,
    grade: '0',
    required: [],
    forbidden: [],
  },
  make: {
    enabled: false,
    grade: '0',
    target: '',
    location: 'sub',
  },
  jupyter: {
    enabled: false,
    type: 'extract',
    grade: '0',
  },
  run: {
    type: 'custom',
    location: 'cloud',
    user: '',
    password: null,
    host: '',
    path: '',
    clear: false,
    cmd: '',
    timeout: 60,
    grade: '0',
    parallel: false,
  },
};

export const DEFAULT_LOGS = {
  from: null,
  to: null,
  status: null,
  perPage: 10,
};

export const GRADE_KWS = [
  {
    id: 'grader.file_path',
    display: '{{grader.file_path}}',
  },
  {
    id: 'grader.all_file_paths',
    display: '{{grader.all_file_paths}}',
  },
  {
    id: 'grader.folder_path',
    display: '{{grader.folder_path}}',
  },
  {
    id: 'submission.file_path',
    display: '{{submission.file_path}}',
  },
  {
    id: 'submission.all_file_paths',
    display: '{{submission.all_file_paths}}',
  },
  {
    id: 'submission.folder_path',
    display: '{{submission.folder_path}}',
  },
];

export const SOFTWARE = [
  {
    name: 'Java',
    versions: ['21', '11', '8'],
  },
  {
    name: 'JUnit',
    versions: [null, '5', '4'],
  },
  {
    name: 'Python',
    versions: ['3.13', '3.12', '3.10', '3.8'],
  },
  {
    name: 'GCC',
    versions: null,
  },
  {
    name: 'G++',
    versions: null,
  },
  {
    name: 'Maven',
    versions: null,
  },
  {
    name: 'Gradle',
    versions: null,
  },
  {
    name: 'NodeJS',
    versions: null,
  },
  {
    name: 'Make',
    versions: null,
  },
  {
    name: 'npm',
    versions: null,
  },
  {
    name: 'Rust',
    versions: null,
  },
  {
    name: 'Git',
    versions: null,
  },
];
