// Returns a color from a gradient of colors
// colors: an array of objects with a color and a value
// value: the value to get the color for
export function gradient(colors, value) {
  if (colors.length === 0) return null;
  if (colors.length === 1) return colors[0].color;
  if (colors.length !== new Set(colors.map((c) => c.value)).size)
    throw new Error('Duplicate values in colors');
  const sortedColors = colors.toSorted((a, b) => a.value - b.value);
  let lower = sortedColors[0];
  let upper = sortedColors[sortedColors.length - 1];
  for (let i = 1; i < sortedColors.length; i++) {
    if (sortedColors[i].value > value) {
      break;
    }
    lower = sortedColors[i];
  }
  for (let i = sortedColors.length - 2; i >= 0; i--) {
    if (sortedColors[i].value < value) {
      break;
    }
    upper = sortedColors[i];
  }
  if (lower === upper) return lower.color;
  const w = (value - lower.value) / (upper.value - lower.value);
  const lowerColor = hexToRgb(lower.color);
  const upperColor = hexToRgb(upper.color);
  const color = blend(lowerColor, upperColor, w);
  return rgbToHex(color);
}

export function blend(c1, c2, w) {
  return {
    r: Math.round(c1.r * (1 - w) + c2.r * w),
    g: Math.round(c1.g * (1 - w) + c2.g * w),
    b: Math.round(c1.b * (1 - w) + c2.b * w),
  };
}

export function hexToRgb(hex) {
  if (hex.startsWith('#')) hex = hex.slice(1);
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((char) => char + char)
      .join('');
  }
  const int = parseInt(hex, 16);
  return {
    r: (int >> 16) & 255,
    g: (int >> 8) & 255,
    b: int & 255,
  };
}

export function rgbToHex({ r, g, b }) {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
}
