import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { gradient } from '../../util/colors';
import { calculateMean } from '../../util/descriptive_statistics';
import { title } from '../../util/string_methods';
import { GRADIENT_COLORS } from '../inspect/Constants';
import {
  COLORS,
  DescriptiveStatistics,
  DisplayOnEmpty,
  DisplayOnError,
} from './Common';

export function MatchDistribution({ data, type }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  try {
    let allPercentages;
    if (type === 'code') allPercentages = data.flatMap((x) => [x.p0, x.p1]);
    else allPercentages = data.map((x) => x.p);
    allPercentages = allPercentages.sort((a, b) => a - b);

    const percentCounts = allPercentages.reduce((acc, val) => {
      acc[val] = (acc[val] || 0) + 1;
      return acc;
    }, {});
    const dataToPlot = Object.entries(percentCounts).map(([k, v]) => {
      return { Match: k, Count: v };
    });

    return (
      <ResponsiveContainer>
        <BarChart data={dataToPlot}>
          <CartesianGrid />
          <XAxis
            dataKey={'Match'}
            label={{ value: 'Match %', position: 'insideBottom', offset: -5 }}
            type='number'
            interval={0}
            padding={{ left: 80, right: 80 }}
          />
          <YAxis
            label={{ value: 'Count', angle: -90, dx: -15 }}
            type='number'
          />
          <Tooltip />
          <Bar dataKey={'Count'} fill={COLORS[0]} maxBarSize={100} />
          <Legend
            content={<DescriptiveStatistics sortedData={allPercentages} />}
            verticalAlign='bottom'
          />
        </BarChart>
      </ResponsiveContainer>
    );
  } catch {
    return <DisplayOnError />;
  }
}

export function InspectionsPerPeriod({ data, by }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  try {
    const groupedBy = data.reduce((acc, i) => {
      const key = i[by];
      if (i.target === 'code') {
        acc[key] = { ...(acc?.[key] || {}), code: (acc?.[key]?.code || 0) + 1 };
      } else if (i.target === 'text') {
        acc[key] = { ...(acc?.[key] || {}), text: (acc?.[key]?.text || 0) + 1 };
      }
      return acc;
    }, {});
    const dataToPlot = Object.entries(groupedBy).map(([k, v]) => {
      return { Period: k, Code: v.code, Text: v.text };
    });
    return (
      <ResponsiveContainer>
        <BarChart data={dataToPlot} margin={{ bottom: 15 }}>
          <CartesianGrid />
          <XAxis
            dataKey={'Period'}
            label={{
              value: by.toUpperCase(),
              position: 'insideBottom',
              offset: -10,
            }}
          />
          <YAxis
            label={{ value: 'Num Students', angle: -90, dx: -20 }}
            type='number'
          />
          <Tooltip />
          <Bar dataKey={'Code'} fill={COLORS[0]} maxBarSize={100} />
          <Bar dataKey={'Text'} fill={COLORS[1]} maxBarSize={100} />
        </BarChart>
      </ResponsiveContainer>
    );
  } catch {
    return <DisplayOnError />;
  }
}

export function AverageMatch({ data, type }) {
  if (data === null || data.length < 1) return <DisplayOnEmpty />;
  try {
    const groupedBy = data.reduce((acc, x) => {
      if (type === 'code') {
        acc[x.s0] = [...(acc[x.s0] || []), x.p0];
        acc[x.s1] = [...(acc[x.s1] || []), x.p1];
      } else if (type === 'text') {
        acc[x.s] = [...(acc[x.s] || []), x.p];
      }
      return acc;
    }, {});
    const dataToPlot = Object.entries(groupedBy)
      .map(([k, v]) => {
        return {
          Student: title(k.replaceAll('_', ' ')),
          Match: calculateMean(v),
        };
      })
      .sort((a, b) => a.Match - b.Match);

    return (
      <ResponsiveContainer>
        <BarChart data={dataToPlot} margin={{ bottom: 130, right: 40 }}>
          <CartesianGrid />
          <XAxis
            dataKey={'Student'}
            label={{
              value: 'Student Name',
              position: 'insideBottom',
              offset: -120,
            }}
            textAnchor='start'
            interval={0}
            angle={90}
            tick={{ fontSize: '12' }}
          />
          <YAxis
            label={{ value: 'Match %', angle: -90, dx: -15 }}
            type='number'
          />
          <Tooltip />
          <Bar dataKey={'Match'} maxBarSize={100}>
            {dataToPlot.map((entry, i) => (
              <Cell key={i} fill={gradient(GRADIENT_COLORS, entry.Match)} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  } catch {
    return <DisplayOnError />;
  }
}
