import { useRef, useState } from 'react';
import {
  Accordion,
  Alert,
  Button,
  Col,
  Form,
  InputGroup,
  Row,
  Table,
} from 'react-bootstrap';
import { DocsHelp } from '../docs/Docs';
import Mentions from '../reusable/Mentions';
import { GRADE_KWS } from './Constants';
import { useTasks } from './GeneralConfig';

function TaskHeader({ task, title, iconName, helpId }) {
  const { tasks, setTasks, editState } = useTasks();

  return (
    <Accordion.Header className='d-flex'>
      <span>
        <i className={`bi bi-${iconName} me-2`} />
      </span>
      <span className='flex-grow-1 fw-bold'>{title}</span>
      <span
        className='me-1 d-flex flex-row align-items-center'
        onClick={(e) => e.stopPropagation()}
      >
        <Form.Check
          id='gradeCheck12'
          disabled={!editState}
          type={'switch'}
          checked={tasks[task].enabled}
          onFocus={() => null}
          onChange={(e) => {
            e.stopPropagation();
            setTasks({
              ...tasks,
              [task]: { ...tasks[task], enabled: e.target.checked },
            });
          }}
        />
        <DocsHelp at={helpId} />
      </span>
    </Accordion.Header>
  );
}

function CheckFileKW({ arr }) {
  const { tasks, setTasks, editState } = useTasks();
  const inputRef = useRef(null);

  const addToArr = () => {
    const value = inputRef.current.value.trim();
    if (value === '') return;

    const updatedVals = Array.from(new Set([value, ...tasks.check_files[arr]]));
    setTasks({
      ...tasks,
      check_files: { ...tasks.check_files, [arr]: updatedVals },
    });
    inputRef.current.value = '';
  };
  const delFromArr = (v) => {
    setTasks({
      ...tasks,
      check_files: {
        ...tasks.check_files,
        [arr]: tasks.check_files[arr].filter((item) => item !== v),
      },
    });
  };

  return (
    <fieldset disabled={!editState}>
      <Row>
        <Col sm={'auto'} className='mb-2'>
          <InputGroup size='sm'>
            <InputGroup.Text style={{ width: '80px' }}>File(s)</InputGroup.Text>
            <Form.Control
              size='sm'
              type='text'
              ref={inputRef}
              placeholder='Glob pattern'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  e.stopPropagation();
                  addToArr();
                }
              }}
            />
          </InputGroup>
        </Col>
        <Col>
          <Button
            size='sm'
            onClick={addToArr}
            variant='info'
            className='text-light'
          >
            <i className={'bi bi-plus-lg'} />
            &nbsp;Add
          </Button>
        </Col>
      </Row>
      <Table
        responsive
        bordered
        size='sm'
        className='text-center'
        style={{ width: 'auto' }}
      >
        <tbody>
          {tasks.check_files[arr].map((item, i) => (
            <tr key={i}>
              <td className='px-2' style={{ verticalAlign: 'middle' }}>
                {arr === 'required' ? 'Any' : 'All'} files matching the
                pattern&nbsp;
                <span className='text-info'>{item}</span>
              </td>
              <td className='px-2' style={{ verticalAlign: 'middle' }}>
                <Button
                  size='sm'
                  variant='danger'
                  onClick={() => delFromArr(item)}
                >
                  <i className={'bi bi-trash-fill text-light'}></i>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </fieldset>
  );
}

function CheckCodeKW({ arr, quantifierExists = false }) {
  const { tasks, setTasks, editState } = useTasks();
  const codeRef = useRef(null);
  const fileRef = useRef(null);
  const quantifierRef = useRef(null);

  const addToArr = () => {
    const codeVal = codeRef.current.value.trim();
    let fileVal = fileRef.current.value.trim();
    if (codeVal === '') return;
    if (fileVal === '') fileVal = '**/*';

    const val = { code: codeVal, file: fileVal };
    if (quantifierExists) val.quantifier = quantifierRef.current.value;

    const uniqueVals = new Set([
      ...tasks.check_code[arr].map(JSON.stringify),
      JSON.stringify(val),
    ]);
    const updatedVals = Array.from(uniqueVals, JSON.parse);

    setTasks({
      ...tasks,
      check_code: { ...tasks.check_code, [arr]: updatedVals },
    });
    codeRef.current.value = '';
    fileRef.current.value = '**/*';
  };

  const delFromArr = (val) => {
    setTasks({
      ...tasks,
      check_code: {
        ...tasks.check_code,
        [arr]: tasks.check_code[arr].filter((item) => item !== val),
      },
    });
  };

  return (
    <fieldset disabled={!editState}>
      <Row
        className='mb-1'
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            addToArr();
          }
        }}
      >
        <Col sm={'auto'} className='mb-2'>
          <InputGroup size='sm'>
            <InputGroup.Text style={{ width: '80px' }}>Code</InputGroup.Text>
            <Form.Control type='text' ref={codeRef} placeholder='regexp' />
          </InputGroup>
        </Col>
        <Col sm={'auto'} className='mb-2'>
          <InputGroup size='sm'>
            <InputGroup.Text style={{ width: '80px' }}>File(s)</InputGroup.Text>
            <Form.Control
              type='text'
              defaultValue={'**/*'}
              ref={fileRef}
              placeholder='glob pattern'
            />
          </InputGroup>
        </Col>
        {quantifierExists && (
          <Col sm={'auto'} className='mb-2'>
            <InputGroup size='sm'>
              <InputGroup.Text style={{ width: '80px' }}>
                Quantifier
              </InputGroup.Text>
              <Form.Select ref={quantifierRef} style={{ maxWidth: '100px' }}>
                <option value={'all'}>All</option>
                <option value={'any'}>Any</option>
              </Form.Select>
            </InputGroup>
          </Col>
        )}
        <Col className='mb-2'>
          <Button
            size='sm'
            onClick={addToArr}
            variant='info'
            className='text-light'
          >
            <i className={'bi bi-plus-lg'} />
            &nbsp;Add
          </Button>
        </Col>
      </Row>
      <Table
        responsive
        bordered
        size='sm'
        className='text-center'
        style={{ width: 'auto' }}
      >
        <tbody>
          {tasks.check_code[arr].map((item, i) => (
            <tr key={i}>
              <td className='px-2' style={{ verticalAlign: 'middle' }}>
                <span className='text-danger fw-bold'>{item.code}</span> must{' '}
                {!quantifierExists && 'not'} be in&nbsp;
                {item.quantifier === 'all' ? (
                  <>
                    <span className='text-success fw-bold'>all</span>{' '}
                    files&nbsp;
                  </>
                ) : (
                  <>
                    <span className='text-success fw-bold'>any</span> one
                    file&nbsp;
                  </>
                )}
                matched by the path&nbsp;
                <span className='text-info'>{item.file}</span>
              </td>
              <td className=' px-2' style={{ verticalAlign: 'middle' }}>
                <Button
                  size='sm'
                  variant='danger'
                  onClick={() => delFromArr(item)}
                >
                  <i className={'bi bi-trash-fill text-light'}></i>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </fieldset>
  );
}

function TaskFooter({ task, title = 'if Task Fails' }) {
  const { tasks, setTasks, editState } = useTasks();

  return (
    <fieldset disabled={!editState}>
      <Form.Label>Grade to Return to Students {title}</Form.Label>
      <Row>
        <Col sm={'auto'}>
          <Form.Control
            size='sm'
            type={'text'}
            minLength={0}
            maxLength={10}
            value={tasks[task].grade}
            required={tasks[task].enabled === true}
            onChange={(e) =>
              setTasks({
                ...tasks,
                [task]: { ...tasks[task], grade: e.target.value },
              })
            }
          />
        </Col>
      </Row>
    </fieldset>
  );
}

function MakeBuildHelper() {
  const { tasks, setTasks, editState } = useTasks();

  return (
    <fieldset disabled={!editState}>
      <Row>
        <Col sm={'auto'}>
          <InputGroup size={'sm'}>
            <InputGroup.Text style={{ width: '70px' }}>
              {'Target'}
            </InputGroup.Text>
            <Form.Control
              size='sm'
              type={'text'}
              value={tasks.make.target}
              onChange={(e) =>
                setTasks({
                  ...tasks,
                  make: { ...tasks.make, target: e.target.value },
                })
              }
            />
          </InputGroup>
        </Col>
      </Row>
      <Row className='my-2'>
        <Col sm={'auto'}>
          <InputGroup size='sm'>
            <InputGroup.Text style={{ width: '70px' }}>
              Location
            </InputGroup.Text>
            <Form.Select
              onChange={(e) =>
                setTasks({
                  ...tasks,
                  make: { ...tasks.make, location: e.target.value },
                })
              }
            >
              <option value='sub'>In the submission folder</option>
              <option value='root'>In the root folder</option>
              <option value='recursive'>
                Search Recursively in all folders
              </option>
            </Form.Select>
          </InputGroup>
        </Col>
      </Row>
      <br />
    </fieldset>
  );
}

function JupyterHelper() {
  const { tasks, setTasks, editState } = useTasks();

  return (
    <fieldset disabled={!editState}>
      <Form.Check
        id='gradeCheck22'
        type='radio'
        label='Extract'
        checked={tasks.jupyter.type === 'extract'}
        name='jupyterTask'
        className='fw-bold'
        onChange={() =>
          setTasks({
            ...tasks,
            jupyter: { ...tasks.jupyter, type: 'extract' },
          })
        }
      />
      <Form.Check
        id='gradeCheck23'
        type='radio'
        label='Run'
        checked={tasks.jupyter.type === 'run'}
        name='jupyterTask'
        className='fw-bold'
        onChange={() =>
          setTasks({
            ...tasks,
            jupyter: { ...tasks.jupyter, type: 'run' },
          })
        }
      />
      <hr />
    </fieldset>
  );
}

function Extract() {
  return (
    <Accordion.Item eventKey='extract'>
      <TaskHeader
        task={'extract'}
        title={'Extract'}
        helpId={'grade.tasks.extract'}
        iconName={'file-earmark-zip'}
      />
      <Accordion.Body className='bg-light'>
        <TaskFooter task={'extract'} />
      </Accordion.Body>
    </Accordion.Item>
  );
}

function CheckFiles() {
  return (
    <Accordion.Item eventKey='check_files'>
      <TaskHeader
        task={'check_files'}
        title={'Check Files'}
        helpId={'grade.tasks.check_file'}
        iconName={'file-earmark-check'}
      />
      <Accordion.Body className='bg-light'>
        <p>
          Note that glob patterns are supported. You can use *, **, [], and ? to
          match files and directories.
        </p>
        <Form.Label>Required Files</Form.Label>
        <CheckFileKW arr={'required'} />
        <Form.Label>Allowed Files</Form.Label>
        <CheckFileKW arr={'allowed'} />
        <TaskFooter task={'check_files'} />
      </Accordion.Body>
    </Accordion.Item>
  );
}

function CheckCode() {
  return (
    <Accordion.Item eventKey='check_code'>
      <TaskHeader
        task={'check_code'}
        title={'Check Code'}
        helpId={'grade.tasks.check_code'}
        iconName={'code-slash'}
      />
      <Accordion.Body className='bg-light'>
        <p>
          Note that <b>code</b> is a regular expression. You can test your
          regular expressions{' '}
          <a
            href='https://regexr.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            here.
          </a>
        </p>
        <Form.Label>Required Code</Form.Label>
        <CheckCodeKW arr={'required'} quantifierExists={true} />
        <Form.Label>Forbidden Code</Form.Label>
        <CheckCodeKW arr={'forbidden'} />
        <TaskFooter task={'check_code'} />
      </Accordion.Body>
    </Accordion.Item>
  );
}

function MakeBuild() {
  return (
    <Accordion.Item eventKey='make'>
      <TaskHeader
        task={'make'}
        title={'Run Make'}
        helpId={'grade.tasks.make'}
        iconName={'tools'}
      />
      <Accordion.Body className='bg-light'>
        <MakeBuildHelper />
        <TaskFooter task={'make'} />
      </Accordion.Body>
    </Accordion.Item>
  );
}

function Jupyter() {
  return (
    <Accordion.Item eventKey='jupyter'>
      <TaskHeader
        task={'jupyter'}
        title={'Juypter'}
        helpId={'grade.tasks.jupyter'}
        iconName={'journal-code'}
      />
      <Accordion.Body className='bg-light'>
        <JupyterHelper />
        <TaskFooter task={'jupyter'} />
      </Accordion.Body>
    </Accordion.Item>
  );
}

function GradeCommand() {
  const { tasks, setTasks, editState } = useTasks();
  const [passHidden, setPassHidden] = useState(true);

  return (
    <Accordion.Item eventKey='gradecommand'>
      <Accordion.Header className='d-flex'>
        <span>
          <i className={`bi bi-terminal me-2`} />
        </span>
        <span className='flex-grow-1 fw-bold'>Run Grader</span>
        <span
          className='me-1 d-flex flex-row align-items-center'
          onClick={(e) => e.stopPropagation()}
        >
          <Form.Check
            id='gradeCheck14'
            disabled
            type={'switch'}
            checked
            style={{ cursor: 'default' }}
          />
          <DocsHelp at={'grade.tasks.run_grader'} />
        </span>
      </Accordion.Header>
      <Accordion.Body className='bg-light'>
        <fieldset disabled={!editState}>
          <Form.Check
            id='gradeCheck15'
            type='radio'
            label='Use Custom Grading Script'
            checked={tasks.run.type === 'custom'}
            name='runtype'
            className='fw-bold'
            onChange={() =>
              setTasks({ ...tasks, run: { ...tasks.run, type: 'custom' } })
            }
          />
          <Form.Check
            id='gradeCheck16'
            type='radio'
            label='Use Table Grading'
            checked={tasks.run.type === 'table'}
            name='runtype'
            className='fw-bold'
            onChange={() =>
              setTasks({ ...tasks, run: { ...tasks.run, type: 'table' } })
            }
          />
        </fieldset>
        <hr />
        {tasks.run.type === 'custom' ? (
          <fieldset disabled={!editState}>
            <Form.Check
              id='gradeCheck17'
              type='radio'
              label='Grade in the Cloud'
              checked={tasks.run.location === 'cloud'}
              name='loctype'
              className='fw-bold'
              onChange={() =>
                setTasks({
                  ...tasks,
                  run: { ...tasks.run, location: 'cloud' },
                })
              }
            />
            <Form.Check
              id='gradeCheck18'
              type='radio'
              label='Grade in Your Machine'
              checked={tasks.run.location === 'ssh'}
              name='loctype'
              className='fw-bold'
              onChange={() =>
                setTasks({
                  ...tasks,
                  run: { ...tasks.run, location: 'ssh' },
                })
              }
            />
            <br />
            {tasks.run.location === 'ssh' && (
              <Row>
                <Col>
                  <Alert variant='danger' className='p-2'>
                    Use with caution as running student submissions on your
                    machine could be dangerous...
                  </Alert>
                </Col>
                <Form.Label>Hostname</Form.Label>
                <Col sm={'auto'}>
                  <Form.Control
                    className='mb-2'
                    required={tasks.run.location === 'ssh'}
                    size='sm'
                    type='text'
                    value={tasks.run.host}
                    onChange={(e) =>
                      setTasks({
                        ...tasks,
                        run: { ...tasks.run, host: e.target.value },
                      })
                    }
                  />
                </Col>
                <Form.Label>Username</Form.Label>
                <Col sm={'auto'}>
                  <Form.Control
                    className='mb-2'
                    required={tasks.run.location === 'ssh'}
                    size='sm'
                    type='text'
                    value={tasks.run.user}
                    onChange={(e) =>
                      setTasks({
                        ...tasks,
                        run: { ...tasks.run, user: e.target.value },
                      })
                    }
                  />
                </Col>
                <Form.Label>Password</Form.Label>
                <Col sm={'auto'}>
                  <Form.Control
                    autoComplete='new-password'
                    size='sm'
                    type={passHidden ? 'password' : 'text'}
                    onChange={(e) =>
                      setTasks({
                        ...tasks,
                        run: { ...tasks.run, password: e.target.value },
                      })
                    }
                  />
                </Col>
                {tasks.run.password !== '' && tasks.run.password !== null && (
                  <Col className='ps-0' sm={'auto'}>
                    <Form.Check
                      id='gradeCheck19'
                      label='Show Password'
                      className='pt-1'
                      onChange={() => setPassHidden(!passHidden)}
                    />
                  </Col>
                )}
                <Form.Text className='mb-2'>
                  You will not be able to see your password after you save it.
                  In order to change it, you must overwrite it.
                </Form.Text>
                <Form.Label>Host Path</Form.Label>
                <Col>
                  <Form.Control
                    className='mb-2'
                    required={tasks.run.location === 'ssh'}
                    size='sm'
                    type='text'
                    value={tasks.run.path}
                    onChange={(e) =>
                      setTasks({
                        ...tasks,
                        run: { ...tasks.run, path: e.target.value },
                      })
                    }
                  />
                </Col>
                <Row>
                  <Col sm={'auto'}>
                    <Form.Check
                      id='gradeCheck20'
                      type='checkbox'
                      label='Clear Directory before Copying Files to Path'
                      checked={tasks.run.clear}
                      onChange={() =>
                        setTasks({
                          ...tasks,
                          run: { ...tasks.run, clear: !tasks.run.clear },
                        })
                      }
                    />
                  </Col>
                </Row>
              </Row>
            )}
            <Form.Label>Command</Form.Label>
            <Col sm={'auto'}>
              <Mentions
                disabled={!editState}
                KWS={GRADE_KWS}
                value={tasks.run.cmd}
                onChange={(_msg, val) => {
                  setTasks({ ...tasks, run: { ...tasks.run, cmd: val } });
                }}
                placeholder='Enter command to setup/run script here...'
                singleLine={true}
              />
            </Col>
          </fieldset>
        ) : (
          <fieldset disabled={!editState}>
            Make sure your &quot;Grading Assets&quot; contain the following:
            <ul>
              <li>&quot;.main&quot; file listing the tests</li>
              <li>
                &quot;master&quot; folder containing the expected outputs (if
                used by .main file)
              </li>
            </ul>
            <Form.Label>Tests Settings</Form.Label>
            <Form.Check
              id='gradeCheck21'
              label='Parallelize Tests'
              checked={tasks.run.parallel}
              onChange={() =>
                setTasks({
                  ...tasks,
                  run: {
                    ...tasks.run,
                    parallel: !tasks.run.parallel,
                  },
                })
              }
            />
            {tasks.run.parallel && (
              <Alert variant='danger' className='p-2'>
                You must ensure the tests are thread-safe. For example, tests
                should not write to the same file at the same time, and their
                order should not affect the results.
              </Alert>
            )}
          </fieldset>
        )}
        <hr />
        <Form.Label>Timeout After</Form.Label>
        <Row as={'fieldset'} disabled={!editState}>
          <Col sm={'auto'}>
            <InputGroup size='sm'>
              <Form.Control
                size='sm'
                type='number'
                min={1}
                max={60}
                value={tasks.run.timeout}
                onChange={(e) =>
                  setTasks({
                    ...tasks,
                    run: {
                      ...tasks.run,
                      timeout: parseInt(e.target.value, 10),
                    },
                  })
                }
              />
              <InputGroup.Text>secs</InputGroup.Text>
            </InputGroup>
          </Col>
        </Row>
        <br />
        <TaskFooter task={'run'} title='on Timeout' />
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default function TaskComponents() {
  const [accordionKey, setAccordionKey] = useState('gradecommand');

  return (
    <Accordion
      defaultActiveKey={accordionKey}
      className='small'
      onSelect={(eventKey) => setAccordionKey(eventKey)}
    >
      <Extract />
      <CheckFiles />
      <CheckCode />
      <MakeBuild />
      <Jupyter />
      <GradeCommand />
    </Accordion>
  );
}
