import axios from 'axios';
import { invalidateCache } from '../util/cache';
import { BACKEND_URL } from './api';

const INSPECT_RUN_REGEX = /inspect-[code|text]+-run-[\S]*/;

function getCacheId(type, runId) {
  return `inspect-${type}-run-${runId}`;
}

export function getInspectSummary(courseId, type) {
  return axios.get(BACKEND_URL + '/courses/' + courseId + `/inspect/` + type, {
    cache: false,
  });
}

export function getInspection(courseId, assignmentId, type, id = null) {
  const queries = { id: id };
  return axios
    .get(
      BACKEND_URL +
        '/courses/' +
        courseId +
        '/inspect/' +
        type +
        '/' +
        assignmentId,
      {
        params: queries,
        id: getCacheId(type, id),
      },
    )
    .then((res) => res.data);
}

export function startTextInspection(courseId, assignmentId, data) {
  invalidateCache(INSPECT_RUN_REGEX);
  return axios.post(
    BACKEND_URL + '/courses/' + courseId + '/inspect/text/' + assignmentId,
    data,
  );
}

export function startCodeInspection(courseId, assignmentId, data, file) {
  invalidateCache(INSPECT_RUN_REGEX);
  const formData = new FormData();
  formData.append('config', JSON.stringify(data));

  if (file === null || file instanceof File) formData.append('base_code', file);
  return axios.post(
    BACKEND_URL + '/courses/' + courseId + '/inspect/code/' + assignmentId,
    formData,
  );
}

export function stopInspection(courseId, assignmentId, type) {
  return axios.delete(
    BACKEND_URL +
      '/courses/' +
      courseId +
      '/inspect/' +
      type +
      '/' +
      assignmentId,
  );
}

export function getInspectStats(courseId) {
  return axios
    .get(BACKEND_URL + '/courses/' + courseId + '/inspect/stats')
    .then((response) => response.data);
}
