import { memo, useEffect, useState } from 'react';
import { Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Route, Routes, useNavigate } from 'react-router-dom';
import getCourses from '../../api/courses_api';
import { getCoursePage } from '../../util/canvas_url';
import { title } from '../../util/string_methods';
import Error from '../reusable/Error';
import LoadingSpinner from '../reusable/LoadingSpinner';
import Course from './Course';

function Courses() {
  const [courses, setCourses] = useState(null);

  useEffect(() => {
    getCourses().then(setCourses);
  }, []);

  return courses ? (
    <Routes>
      <Route path='' element={<CoursesView courses={courses} />} />
      {courses.map((x, i) => (
        <Route key={i} path={`${x.id}/*`} element={<Course courseInfo={x} />} />
      ))}
      <Route
        path='/*'
        element={<Error error={404} message={'Page was not found!'} />}
      />
    </Routes>
  ) : (
    <LoadingSpinner text='Loading Courses...' />
  );
}

function CoursesView({ courses }) {
  const navigate = useNavigate();

  function handleOnClick(course) {
    navigate(`${course.id}`);
  }

  function getSemesterName(semesterCode) {
    if (semesterCode === null) return 'No Semester';
    const year = '20' + semesterCode.slice(0, 2);
    const seasonCode = semesterCode.slice(2);
    let season = '';
    switch (seasonCode) {
      case 'fa':
        season = 'Fall';
        break;
      case 'sp':
        season = 'Spring';
        break;
      case 'su':
        season = 'Summer';
        break;
    }
    return `${season} ${year}`.trim();
  }

  return (
    <Row
      xs={1}
      sm={2}
      md={3}
      xl={5}
      className='m-0 h-100 g-4 p-3 overflow-auto'
    >
      {courses.map((x, i) => (
        <Col key={i}>
          <Card className='card-animation'>
            <div className='overflow-hidden m-auto' style={{ height: '220px' }}>
              <Card.Img
                variant='top'
                src={x.image}
                className='pointer'
                onClick={() => handleOnClick(x)}
              />
            </div>
            <Card.Body className='pointer' onClick={() => handleOnClick(x)}>
              <Card.Title className='text-primary truncateTxt overflow-hidden'>
                {x.code}
              </Card.Title>
              <Card.Subtitle style={{ height: '48px' }}>{x.name}</Card.Subtitle>
              <Card.Text className='text-muted mt-2'>
                {getSemesterName(x.semester).toUpperCase()}
              </Card.Text>
            </Card.Body>
            <Card.Footer className='d-flex small text-muted'>
              <div className='flex-grow-1 truncateTxt'>
                Course Status: {title(x.state)}
              </div>
              <OverlayTrigger
                placement='bottom'
                overlay={<Tooltip>Open in Canvas</Tooltip>}
              >
                <a
                  href={getCoursePage(x.id)}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='bi bi-box-arrow-up-right pointer text-black' />
                </a>
              </OverlayTrigger>
            </Card.Footer>
          </Card>
        </Col>
      ))}
    </Row>
  );
}

export default memo(Courses);
