import axios from 'axios';
import { BACKEND_URL } from './api.js';

const EXTENSIONS_CACHE_ID = 'extensions-cache-id';

function getCacheId(courseId) {
  return EXTENSIONS_CACHE_ID + '-' + courseId;
}

export default function getExtensionsForCourse(courseId) {
  return axios
    .get(BACKEND_URL + '/courses/' + courseId + '/extend', {
      id: getCacheId(courseId),
    })
    .then((response) => response.data);
}

export function getExtendStats(courseId) {
  return axios
    .get(BACKEND_URL + '/courses/' + courseId + '/extend/stats')
    .then((response) => response.data);
}

export function addExtension(courseId, studentId, days, time) {
  return axios.post(
    BACKEND_URL + '/courses/' + courseId + '/extend/' + studentId,
    {
      days: days,
      time: time,
    },
    {
      cache: {
        update: {
          [getCacheId(courseId)]: (extensions_cache, response) => {
            if (
              extensions_cache.state !== 'cached' ||
              response.status !== 200
            ) {
              return 'ignore';
            }
            extensions_cache.data.data.push({
              student_id: studentId,
              days: days,
              time: time,
            });
            return extensions_cache;
          },
        },
      },
    },
  );
}

export function deleteExtension(courseId, studentId) {
  return axios.delete(
    BACKEND_URL + '/courses/' + courseId + '/extend/' + studentId,
    {
      cache: {
        update: {
          [getCacheId(courseId)]: (extensions_cache, response) => {
            if (
              extensions_cache.state !== 'cached' ||
              response.status !== 200
            ) {
              return 'ignore';
            }
            const data = extensions_cache.data.data;
            for (let i = data.length - 1; i >= 0; i--) {
              if (data[i]?.student_id === studentId) {
                data.splice(i, 1);
              }
            }
            return extensions_cache;
          },
        },
      },
    },
  );
}

export function updateExtension(courseId, studentId, days, time) {
  return axios.put(
    BACKEND_URL + '/courses/' + courseId + '/extend/' + studentId,
    {
      days: days,
      time: time,
    },
    {
      cache: {
        update: {
          [getCacheId(courseId)]: (extensions_cache, response) => {
            if (
              extensions_cache.state !== 'cached' ||
              response.status !== 200
            ) {
              return 'ignore';
            }
            for (const extension of extensions_cache.data.data) {
              if (extension?.student_id === studentId) {
                extension.days = days;
                extension.time = time;
                break;
              }
            }
            return extensions_cache;
          },
        },
      },
    },
  );
}
