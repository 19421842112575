import { useNavigate } from 'react-router-dom';
import '../../../index.scss';
import { memo, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import login from '../../api/authentication_api';
import { is_dev } from '../../util/env';
import Stats from './Stats';

const oauth2_url = 'https://colostate.instructure.com/login/oauth2/auth';
const scopes = [
  'url:GET|/api/v1/users/:user_id/profile',
  'url:GET|/api/v1/courses/:course_id/assignments',
  'url:GET|/api/v1/courses/:course_id/assignments/:assignment_id/overrides',
  'url:POST|/api/v1/courses/:course_id/assignments/:assignment_id/overrides',
  'url:PUT|/api/v1/courses/:course_id/assignments/overrides',
  'url:DELETE|/api/v1/courses/:course_id/assignments/:assignment_id/overrides/:id',
  'url:GET|/api/v1/courses/:course_id/assignment_groups',
  'url:GET|/api/v1/courses',
  'url:GET|/api/v1/courses/:id',
  'url:GET|/api/v1/courses/:course_id/users',
  'url:GET|/api/v1/courses/:id/late_policy',
  'url:GET|/api/v1/courses/:course_id/quizzes',
  'url:POST|/api/v1/courses/:course_id/quizzes/:quiz_id/extensions',
  'url:GET|/api/v1/courses/:course_id/students/submissions',
  'url:GET|/api/v1/courses/:course_id/assignments/:assignment_id/submissions',
  'url:PUT|/api/v1/courses/:course_id/assignments/:assignment_id/submissions/:user_id',
];
const dev_redirect_uri = 'http://localhost:3000/oauth2/response';
const prod_redirect_uri = 'https://ramdesk.cs.colostate.edu/oauth2/response';
const redirect_uri = is_dev() ? dev_redirect_uri : prod_redirect_uri;
const oauth2_params = {
  client_id: '37160000000000300',
  response_type: 'code',
  redirect_uri: redirect_uri,
  scope: scopes.join(' '),
};

function createLoginUrl() {
  let login_url = oauth2_url + '?';

  Object.entries(oauth2_params).forEach(([key, value]) => {
    login_url += `${key}=${value}&`;
  });
  login_url = login_url.slice(0, -1);

  return login_url;
}

const login_url = createLoginUrl();

function Login({ name, setName, setPicture, setRole, setId }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (name) {
      navigate('/courses', { replace: true });
    }
  }, [name]);

  function token_login(token) {
    login(null, token).then((data) => {
      setName(data.name);
      setPicture(data.picture);
      setRole(data.role);
      setId(data.id);
      navigate('/courses');
    });
  }
  window.__amp415__ = token_login;

  function onClick() {
    window.location.href = login_url;
  }

  return (
    <div className='w-100 h-100 d-flex flex-column align-items-center'>
      <div className='text-center pt-5 mt-5'>
        <h1 className='text-primary' style={{ fontSize: '80px' }}>
          <b>RamDesk</b>
        </h1>
        A suite of tools for managing your Canvas courses.
        <hr className='text-secondary border-2' style={{ opacity: 0.8 }} />
        <div className='d-flex gap-2'>
          <div className='w-75'>
            <Button
              variant='primary'
              size='lg'
              className='w-100 border-0 rounded-1 shadow pt-1'
              onClick={onClick}
            >
              Login with Canvas
            </Button>
          </div>
          <div className='w-25'>
            <Button
              variant='light'
              size='lg'
              className='w-100 border-0  rounded-1 shadow pt-1'
              onClick={() => navigate('/docs')}
            >
              Docs
            </Button>
          </div>
        </div>
      </div>
      <Stats />
    </div>
  );
}

export default memo(Login);
