export function deepCompare(newObj, oldObj) {
  let objToReturn = {};
  let keysPresent = [];
  let keysAbsent = [];

  (function recurseObj(obj1, obj2, completeObj, parentKey = '') {
    for (const k in obj2) {
      if (Object.prototype.hasOwnProperty.call(obj2, k)) {
        const fullKey = parentKey ? `${parentKey}.${k}` : k;
        if (
          typeof obj2[k] === 'object' &&
          obj2[k] !== null &&
          !Array.isArray(obj2[k])
        ) {
          completeObj[k] = {};
          recurseObj(obj1?.[k] || {}, obj2[k], completeObj[k], fullKey);
        } else {
          if (Object.prototype.hasOwnProperty.call(obj1, k)) {
            completeObj[k] = obj1[k];
            keysPresent.push(fullKey);
          } else {
            completeObj[k] = obj2[k];
            keysAbsent.push(fullKey);
          }
        }
      }
    }
  })(newObj, oldObj, objToReturn);

  return {
    modifiedObj: objToReturn,
    keysPresent: keysPresent,
    keysAbsent: keysAbsent,
  };
}
