import { Card, Col, Form, ListGroup, Modal, Row } from 'react-bootstrap';
import { useGlobalVars } from '../../../App';
import LoadingSpinner from '../reusable/LoadingSpinner';

export const defaultCurrVal = { id: null, name: '' };

export function getSemesterName(id) {
  let name = `20${id.slice(0, 2)} `;
  const sem = id.slice(2, 4);

  if (sem === 'fa') name += 'Fall';
  else if (sem === 'sp') name += 'Spring';
  else if (sem === 'sm') name += 'Summer';

  return name;
}

export function getUniqueItems(data, by) {
  let set = new Set([...data.map((x) => x[by])]);
  return [...set];
}

export function getUniqueItemsInList(data, by) {
  let set = new Set();
  data.forEach((x) => {
    x[by].forEach((x) => set.add(x));
  });
  return [...set];
}

export function getUniqueItemsAsObj(id, name, data) {
  const set = new Set();
  data.forEach((x) => set.add(JSON.stringify({ id: x[id], name: x[name] })));
  const uniqueItems = Array.from(set).map((x) => JSON.parse(x));
  return uniqueItems;
}

export function TotalStats({ title, stat = '', subtitle }) {
  return (
    <ListGroup.Item className='bg-white text-black'>
      <div className='d-flex flex-row'>
        <div className='w-75 truncateTxt'>
          <div className='fw-bold truncateTxt'>{title}</div>
          <span className='small text-muted'>{subtitle}</span>
        </div>
        <div className='m-auto fs-4 fw-bold'>{stat}</div>
      </div>
    </ListGroup.Item>
  );
}

export function FilterDashboard({ child }) {
  return (
    <Row className='pb-3'>
      <Col xs={12}>
        <Card className='shadow-sm'>
          <Card.Header className='bg-primary fw-bold text-light'>
            Filter Dashboard
          </Card.Header>
          <Card.Body>
            <Form>
              <Row xs={1} lg={3}>
                {child}
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export function GeneralStatistics({ child }) {
  return (
    <Card className='shadow-sm'>
      <Card.Header className='bg-primary text-light fw-bold truncateTxt'>
        General Statistics
      </Card.Header>
      {child}
    </Card>
  );
}

export function FilteredStatistics({ getHead, getBody, showDef }) {
  return (
    <Card className='shadow-sm'>
      <Card.Header className='bg-primary text-light fw-bold'>
        <div className='truncateTxt'>{getHead()}</div>
      </Card.Header>
      <ListGroup variant='flush'>
        {showDef === '' ? (
          <div className='p-2 small'>
            Filter the data to show relevant stats here...
          </div>
        ) : (
          getBody()
        )}
      </ListGroup>
    </Card>
  );
}

export function Wrapper({ children, ifNull }) {
  const { setShowModal } = useGlobalVars();

  function makeFull(child) {
    setShowModal(
      <Modal fullscreen show scrollable onHide={() => setShowModal(null)}>
        <Modal.Header className='d-flex align-items-center bg-primary text-light p-2 fw-bold'>
          <div className='flex-grow-1'>Plots & Tables</div>
          <i
            className='bi bi-x-lg pointer'
            onClick={() => setShowModal(null)}
          />
        </Modal.Header>
        <Modal.Body>{child}</Modal.Body>
      </Modal>,
    );
  }

  return (
    <div className='d-flex flex-column p-3 h-100 bg-light border overflow-auto rounded-3'>
      {ifNull ? (
        <LoadingSpinner text='Loading Dashboard...' />
      ) : (
        <>
          {children[0]}
          <Row className='m-0 d-flex flex-grow-1 gap-3'>
            <Col className='px-0'>
              <Card className='h-100 shadow-sm'>
                <Card.Header className='d-flex bg-primary fw-bold text-light'>
                  <div className='flex-grow-1'>Plots & Tables</div>
                  <i
                    className='bi bi-arrows-fullscreen pointer'
                    onClick={() => makeFull(children[3])}
                  />
                </Card.Header>
                {children[3]}
              </Card>
            </Col>
            <Col xs={12} md={3} xl={2} className='px-0'>
              {children[1]}
              <br />
              {children[2]}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}
