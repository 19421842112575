import React, { memo, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { getInspectSummary } from '../../api/inspect_api';
import Error from '../reusable/Error';
import LoadingSpinner from '../reusable/LoadingSpinner';
import CodeReport from './code/CodeReport';
import Dashboard from './Dashboard';
import Statistics from './Statistics';
import TextReport from './text/TextReport';

function Inspect({ courseId, assignments }) {
  const [selectedTool, setSelectedTool] = useState('code');
  const [configStats, setConfigStats] = useState(null);
  const [viewChange, setViewChange] = useState(false);
  const [checkUpdate, setCheckUpdate] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.match(/(code|text)+\/*$/g))
      navigate(location.pathname.slice(0, location.pathname.lastIndexOf('/')));
  }, [location]);

  function getName(id) {
    const result = assignments.find((item) => item.id === id);
    return result?.name || null;
  }

  function findHighestScore(results) {
    if (typeof results === 'string' || results.length < 1) return '—';

    let scores = [];
    if (selectedTool === 'code')
      scores = results.map((x) => Math.max(parseInt(x.p0), parseInt(x.p1)));
    else if (selectedTool === 'text')
      scores = results.map((x) => parseInt(x.p));
    return Math.max(...scores);
  }

  function getAndSetSummary() {
    getInspectSummary(courseId, selectedTool).then((res) => {
      const summary = res.data.sort((a, b) => b.status.localeCompare(a.status));

      if (summary.find((x) => x.status === 'running' || x.status === 'queued'))
        setCheckUpdate(true);
      else setCheckUpdate(false);

      const arrIds = summary.map((x) => x.assignment_id);
      for (const assign of assignments) {
        if (!arrIds.includes(assign.id)) {
          summary.push({
            assignment_id: assign.id,
            status: null,
            timestamp: null,
          });
        }
      }
      summary.forEach((x) => {
        x.name = getName(x.assignment_id);
        x.status = x.status === null ? '—' : x.status;
        x.timestamp = x.timestamp === null ? '—' : new Date(x.timestamp);
        x.highestScore = x?.results == null ? '—' : findHighestScore(x.results);
      });
      setConfigStats(summary);
    });
  }

  useEffect(() => {
    if (checkUpdate) {
      const intervalId = setInterval(getAndSetSummary, 5000);
      return () => clearInterval(intervalId);
    }
  }, [checkUpdate]);

  useEffect(() => {
    if (assignments === null) return;
    getAndSetSummary();
  }, [courseId, assignments, selectedTool, viewChange]);

  return configStats ? (
    <Routes>
      <Route
        path=''
        element={
          <Dashboard
            courseId={courseId}
            configStats={configStats}
            setViewChange={setViewChange}
            selectedTool={selectedTool}
            setSelectedTool={setSelectedTool}
          />
        }
      />
      {configStats.map((stat, i) => (
        <React.Fragment key={i}>
          <Route
            path={`/code/${stat.assignment_id}`}
            element={<CodeReport stat={stat} courseId={courseId} />}
          />
          <Route
            path={`/text/${stat.assignment_id}`}
            element={<TextReport stat={stat} courseId={courseId} />}
          />
        </React.Fragment>
      ))}
      <Route
        path='/statistics'
        element={<Statistics courseId={courseId} assignments={assignments} />}
      />
      <Route
        path='/*'
        element={<Error error={404} message={'Page was not found!'} />}
      />
    </Routes>
  ) : (
    <LoadingSpinner text='Loading Assignments...' />
  );
}

export default memo(Inspect);
