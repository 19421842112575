import { memo, useEffect, useRef, useState } from 'react';
import { Col, InputGroup, ListGroup } from 'react-bootstrap';
import { getGradeStats } from '../../api/grade_api.js';
import * as cp from '../plots/Common';
import * as gp from '../plots/GradePlots';
import PlotsContainer from '../plots/PlotsContainer';
import * as wrapper from '../plots/Wrapper.js';
import SearchSelect from '../reusable/SearchSelect.js';

function Statistics({ courseId, assignments }) {
  const [data, setData] = useState(null);
  const [currData, setCurrData] = useState([]);
  const [asgOptions, setAsgOptions] = useState(null);
  const currAssignment = useRef(wrapper.defaultCurrVal);

  function getAssignmentName(id) {
    const result = assignments.find((item) => item.id === id);
    return result.name || null;
  }

  useEffect(() => {
    getGradeStats(courseId).then((res) => {
      const updatedRes = res.map((x) => ({
        ...x,
        assignment_name: getAssignmentName(x.assignment_id),
      }));
      setData(updatedRes);
      setCurrData(updatedRes);
      setAsgOptions(
        wrapper.getUniqueItemsAsObj(
          'assignment_id',
          'assignment_name',
          updatedRes,
        ),
      );
    });
  }, [courseId]);

  function filterByAssignment(val) {
    let filteredData = data;
    if (val.id !== null) {
      filteredData = data.filter((x) => x['assignment_id'] == val.id);
    }
    setCurrData(filteredData);
    currAssignment.current = val;
  }

  function getUniqueStudentsCount(data) {
    let set = new Set();
    for (const d of data) {
      for (const s of d.submissions || []) {
        set.add(s.student_id);
      }
    }
    return set.size;
  }

  function FilterDashboardHelper() {
    return (
      <Col>
        <InputGroup size='sm' className='mb-1 flex-nowrap'>
          <InputGroup.Text style={{ width: '100px' }}>
            Assignment
          </InputGroup.Text>
          <SearchSelect
            idNameRef={currAssignment}
            items={asgOptions}
            onSelect={filterByAssignment}
            setToNull={false}
            displayId={true}
            placeholder={'ALL'}
          />
        </InputGroup>
      </Col>
    );
  }

  function getTotalStats(data) {
    return [
      <wrapper.TotalStats
        key='0'
        title={'Total Assignments'}
        subtitle={'that have used Grade'}
        stat={wrapper.getUniqueItems(data, 'assignment_id').length}
      />,
      <wrapper.TotalStats
        key='1'
        title={'Total Students'}
        subtitle={'impacted by Grade'}
        stat={getUniqueStudentsCount(data)}
      />,
      <wrapper.TotalStats
        key='2'
        title={'Total Submissions'}
        subtitle={'graded by Grade'}
        stat={data.reduce(
          (total, x) => total + (x.submissions || []).length,
          0,
        )}
      />,
    ];
  }

  function GeneralStatisticsHelper() {
    const stats = getTotalStats(data);
    return <ListGroup variant='flush'>{stats.map((x) => x)}</ListGroup>;
  }

  function getHeader() {
    if (currAssignment.current.id !== null)
      return `Stats for ${currAssignment.current.name}`;
    return `Filtered Stats`;
  }

  function getBody() {
    let arr = getTotalStats(currData).slice(1);
    return <>{arr.map((x) => x)}</>;
  }

  function plotsData() {
    const getLogs = () => {
      let logs = [];
      for (const grader of currData) {
        logs.push(...(grader.submissions || []));
      }
      return logs;
    };

    return [
      {
        title: 'Grader Statistics',
        element: <gp.GraderStats data={currData} />,
        display: true,
      },
      {
        title: 'Tasks Statistics',
        element: <gp.TaskUsage data={currData} />,
        display: true,
      },
      {
        title: 'Submission By Hour',
        element: <cp.PlotByHour _key={'submitted_at'} data={getLogs()} />,
        display: true,
      },
      {
        title: 'Submission By Day',
        element: <cp.PlotByDay _key={'submitted_at'} data={getLogs()} />,
        display: true,
      },
      {
        title: 'Submission By Date',
        element: <cp.PlotByDate _key={'submitted_at'} data={getLogs()} />,
        display: true,
      },
      {
        title: 'Score Distribution (All)',
        element: <gp.ScoreDistribution data={getLogs()} />,
        display: currAssignment.current.id !== null,
      },
      {
        title: 'Score Distribution (Last)',
        element: <gp.ScoreOnLastAttempt data={getLogs()} />,
        display: currAssignment.current.id !== null,
      },
      {
        title: 'Attempt Distribution',
        element: <gp.AttemptDistribution data={getLogs()} />,
        display: true,
      },
      {
        title: 'Avg Score by Attempts',
        element: <gp.GradeByAttempts data={getLogs()} />,
        display: currAssignment.current.id !== null,
      },
    ];
  }

  return (
    <wrapper.Wrapper ifNull={data === null}>
      <wrapper.FilterDashboard child={<FilterDashboardHelper />} />
      <wrapper.GeneralStatistics child={<GeneralStatisticsHelper />} />
      <wrapper.FilteredStatistics
        showDef={currAssignment.current.name}
        getHead={getHeader}
        getBody={getBody}
      />
      <PlotsContainer data={plotsData()} />
    </wrapper.Wrapper>
  );
}

export default memo(Statistics);
